import styled, { css } from "styled-components";

export const BackDrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.3);
  display: ${({ show }) => (show ? "block" : "none")};
  z-index: 1001;
`;
export const ModalWrapper = styled.div`
  position: fixed;
  top: -100;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1002;
  opacity: 0;
  transition: all 1s ease;
  max-height: 90vh;
  ${({ show }) =>
    show &&
    css`
      top: 50%;
      opacity: 1;
    `}

  background: white;
  border-radius: 20px;
  overflow: auto;
  @media (max-width: ${({ theme }) => theme.screens.mobile}) {
    width: 95vw;
  }
`;
