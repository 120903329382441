import React, { useEffect, useState } from "react";
import ExcelExport from "../ExcelExport";

function JobsFilter({
  data,
  companyName,
  setFilteredData,
  downloadResumes,
  disableDownlad,
}) {
  const [professionalExperience, setProfessionalExperience] = useState([]);
  const [yearsOfExperience, setYearsOfExperience] = useState("");
  const [fieldOfExperience, setFieldOfExperience] = useState([]);
  const [testScore, setTestScore] = useState("");
  const [lastCTCgreater, setLastCTCgreater] = useState("");
  const [lastCTCLower, setLastCTCLower] = useState("");
  const [collegeName, setCollegeName] = useState("");
  const [city, setCity] = useState("");
  const [showfilters, setShowFilters] = useState(false);
  const [downloadStudentData, setDownloadStudentData] = useState([]);
  const [marks10, setMarks10] = useState("");
  const [marks12, setMarks12] = useState("");
  const [marksGrad, setMarksGrad] = useState("");
  const [yearsOfExperienceLess, setYearsOfExperienceLess] = useState("");

  useEffect(() => {
    let newFilteredData = [...data];
    if (city && city.length) {
      newFilteredData = newFilteredData.filter((item) =>
        item.city?.toLowerCase().includes(city.toLowerCase())
      );
    }
    if (professionalExperience && professionalExperience.length) {
      newFilteredData = newFilteredData.filter((item) =>
        professionalExperience.some((val) => {
          if (!item?.professionalExperience && val === "Not provided") {
            return true;
          }
          return item?.professionalExperience?.includes(val);
        })
      );
    }
    if (yearsOfExperience) {
      newFilteredData = newFilteredData.filter(
        (item) =>
          parseFloat(item.yearsOfExperience) >= parseFloat(yearsOfExperience)
      );
    }
    if (fieldOfExperience && fieldOfExperience.length) {
      newFilteredData = newFilteredData.filter((item) =>
        fieldOfExperience.some((val) => item.fieldOfExperience.includes(val))
      );
    }
    if (testScore) {
      newFilteredData = newFilteredData.filter(
        (item) => parseFloat(item.testScore) >= parseFloat(testScore)
      );
    }
    if (marks10) {
      newFilteredData = newFilteredData.filter(
        (item) => parseFloat(item.marks10) >= parseFloat(marks10)
      );
    }
    if (marks12) {
      newFilteredData = newFilteredData.filter(
        (item) => parseFloat(item.marks12) >= parseFloat(marks12)
      );
    }
    if (marksGrad) {
      newFilteredData = newFilteredData.filter(
        (item) => parseFloat(item.marksGrad) >= parseFloat(marksGrad)
      );
    }
    if (collegeName) {
      newFilteredData = newFilteredData.filter((item) =>
        item?.collegeName?.toLowerCase().includes(collegeName.toLowerCase())
      );
    }
    if (lastCTCgreater) {
      newFilteredData = newFilteredData.filter(
        (item) => parseInt(item.lastCTC) >= lastCTCgreater
      );
    }
    if (lastCTCLower) {
      newFilteredData = newFilteredData.filter(
        (item) => parseInt(item.lastCTC) <= lastCTCLower
      );
    }
    if (yearsOfExperienceLess) {
      newFilteredData = newFilteredData.filter(
        (item) =>
          parseFloat(item.yearsOfExperience) <=
          parseFloat(yearsOfExperienceLess)
      );
    }
    setFilteredData(newFilteredData);
    const reformated = [];
    newFilteredData.forEach((application) => {
      const item = {};
      item["Name"] = application.name;
      item["Email"] = application.email;
      item["City"] = application.city;
      item["Phone Number"] = application.phoneNumber;
      item["Last CTC"] = application.lastCTC;
      item["Field Of Experience"] = application.fieldOfExperience
        ? application.fieldOfExperience.join(", ")
        : "";
      item["Professional Experience"] = application.professionalExperience
        ? application.professionalExperience.join(", ")
        : "";
      item["Test Score"] = application.testScore;
      item["Year(s) Of Experience"] = application.yearsOfExperience;
      item["10th Marks"] = application.marks10 || "not provided";
      item["12th Marks"] = application.marks12 || "not provided";
      item["Grad Marks"] = application.marksGrad || "not provided";
      item["Resume"] = application.resume;
      item["Time Of Application"] = application.time;

      reformated.push(item);
    });
    setDownloadStudentData(reformated);
  }, [
    setFilteredData,
    data,
    professionalExperience,
    yearsOfExperience,
    fieldOfExperience,
    testScore,
    collegeName,
    lastCTCgreater,
    lastCTCLower,
    city,
    marks10,
    marks12,
    marksGrad,
    yearsOfExperienceLess,
  ]);

  const handleCheckbox = (property, value, checked) => {
    if (property === "proEx") {
      if (checked) {
        const newProExp = [...professionalExperience];
        newProExp.push(value);
        setProfessionalExperience(newProExp);
      } else {
        const newProExp = professionalExperience.filter(
          (item) => item !== value
        );
        setProfessionalExperience(newProExp);
      }
    } else if (property === "foe") {
      if (checked) {
        const newFoe = [...fieldOfExperience];
        newFoe.push(value);
        setFieldOfExperience(newFoe);
      } else {
        const newFoe = fieldOfExperience.filter((item) => item !== value);
        setFieldOfExperience(newFoe);
      }
    }
  };
  return (
    <div className="form custom-form mt-4">
      <div className="d-flex justify-content-between align-items-center flex-wrap">
        <button
          className="btn btn-secondary px-2 py-1 mb-3"
          style={{ marginRight: "200px" }}
          onClick={() => setShowFilters((p) => !p)}
        >
          Filters
        </button>
      </div>

      {showfilters && (
        <div className="d-flex flex-wrap py-4 bg-light">
          <div className="form-group col-12 col-md-4">
            <label className="w-100">By Professional Experience</label>
            <label className="mr-2">
              <input
                type="checkbox"
                value="CA"
                className="mr-1"
                checked={professionalExperience.find((item) => item === "CA")}
                onChange={(e) =>
                  handleCheckbox("proEx", e.target.value, e.target.checked)
                }
              />
              CA
            </label>
            <label className="mr-2">
              <input
                type="checkbox"
                value="CFA"
                className="mr-1"
                checked={professionalExperience.find((item) => item === "CFA")}
                onChange={(e) =>
                  handleCheckbox("proEx", e.target.value, e.target.checked)
                }
              />
              CFA
            </label>
            <label className="mr-2">
              <input
                type="checkbox"
                value="MBA"
                className="mr-1"
                checked={professionalExperience.find((item) => item === "MBA")}
                onChange={(e) =>
                  handleCheckbox("proEx", e.target.value, e.target.checked)
                }
              />
              MBA
            </label>
            <label className="mr-2">
              <input
                type="checkbox"
                value="Not provided"
                className="mr-1"
                checked={professionalExperience.find(
                  (item) => item === "Not provided"
                )}
                onChange={(e) =>
                  handleCheckbox("proEx", e.target.value, e.target.checked)
                }
              />
              Not provided
            </label>
          </div>
          <div className="form-group col-12 col-md-4">
            <label>By Years Of Experience (above or equal)</label>
            <input
              value={yearsOfExperience}
              type="number"
              onChange={(e) => setYearsOfExperience(e.target.value)}
              className="form-control"
            />
          </div>
          <div className="form-group col-12 col-md-4">
            <label>By Years Of Experience (less or equal)</label>
            <input
              value={yearsOfExperienceLess}
              type="number"
              onChange={(e) => setYearsOfExperienceLess(e.target.value)}
              className="form-control"
            />
          </div>

          <div className="form-group col-12 col-md-4">
            <label>By Test Score (above or equal)</label>
            <input
              value={testScore}
              type="number"
              onChange={(e) => setTestScore(e.target.value)}
              className="form-control"
            />
          </div>
          <div className="form-group col-12 col-md-4">
            <label>By 10th marks (above or equal)</label>
            <input
              value={marks10}
              type="number"
              onChange={(e) => setMarks10(e.target.value)}
              className="form-control"
            />
          </div>
          <div className="form-group col-12 col-md-4">
            <label>By 12th marks (above or equal)</label>
            <input
              value={marks12}
              type="number"
              onChange={(e) => setMarks12(e.target.value)}
              className="form-control"
            />
          </div>
          <div className="form-group col-12 col-md-4">
            <label>By Grad marks (above or equal)</label>
            <input
              value={marksGrad}
              type="number"
              onChange={(e) => setMarksGrad(e.target.value)}
              className="form-control"
            />
          </div>
          <div className="form-group col-12 col-md-4">
            <label>By Last CTC (above or equal)</label>
            <input
              value={lastCTCgreater}
              type="number"
              onChange={(e) => setLastCTCgreater(e.target.value)}
              className="form-control"
            />
          </div>
          <div className="form-group col-12 col-md-4">
            <label>By Last CTC (less or equal)</label>
            <input
              value={lastCTCLower}
              type="number"
              onChange={(e) => setLastCTCLower(e.target.value)}
              className="form-control"
            />
          </div>
          <div className="form-group col-12 col-md-4">
            <label>By Latest College Name</label>
            <input
              value={collegeName}
              onChange={(e) => setCollegeName(e.target.value)}
              className="form-control"
            />
          </div>
          <div className="form-group col-12 col-md-4">
            <label>By City</label>
            <input
              value={city}
              onChange={(e) => setCity(e.target.value)}
              className="form-control"
            />
          </div>
          <div className="form-group col-12">
            <label className="w-100">By Field Of Experience</label>
            <label className="mr-2">
              <input
                type="checkbox"
                value="Audit, Taxation or Accountancy"
                name="fieldOfExperience"
                className="mr-1"
                checked={fieldOfExperience.find(
                  (item) => item === "Audit, Taxation or Accountancy"
                )}
                onChange={(e) =>
                  handleCheckbox("foe", e.target.value, e.target.checked)
                }
              />
              Audit, Taxation or Accountancy
            </label>
            <label className="mr-2">
              <input
                type="checkbox"
                value="Sales, Marketing, Business Development"
                name="fieldOfExperience"
                className="mr-1"
                checked={fieldOfExperience.find(
                  (item) => item === "Sales, Marketing, Business Development"
                )}
                onChange={(e) =>
                  handleCheckbox("foe", e.target.value, e.target.checked)
                }
              />
              Sales, Marketing, Business Development
            </label>
            <label className="mr-2">
              <input
                type="checkbox"
                value="IT or Engineering"
                name="fieldOfExperience"
                className="mr-1"
                checked={fieldOfExperience.find(
                  (item) => item === "IT or Engineering"
                )}
                onChange={(e) =>
                  handleCheckbox("foe", e.target.value, e.target.checked)
                }
              />
              IT or Engineering
            </label>
            <label className="mr-2">
              <input
                type="checkbox"
                value="Valuation, Credit, Financial Modeling, Corporate Finance or other Core Finance Fields"
                name="fieldOfExperience"
                className="mr-1"
                checked={fieldOfExperience.find(
                  (item) =>
                    item ===
                    "Valuation, Credit, Financial Modeling, Corporate Finance or other Core Finance Fields"
                )}
                onChange={(e) =>
                  handleCheckbox("foe", e.target.value, e.target.checked)
                }
              />
              Valuation, Credit, Financial Modeling, Corporate Finance or other
              Core Finance Fields
            </label>
            <label className="mr-2">
              <input
                type="checkbox"
                value="No Experience"
                name="fieldOfExperience"
                className="mr-1"
                checked={fieldOfExperience.find(
                  (item) => item === "No Experience"
                )}
                onChange={(e) =>
                  handleCheckbox("foe", e.target.value, e.target.checked)
                }
              />
              No Experience
            </label>
            <label className="mr-2">
              <input
                type="checkbox"
                value="Others"
                name="fieldOfExperience"
                className="mr-1"
                checked={fieldOfExperience.find((item) => item === "Others")}
                onChange={(e) =>
                  handleCheckbox("foe", e.target.value, e.target.checked)
                }
              />
              Others
            </label>
          </div>
        </div>
      )}
      <div className="d-flex justify-content-end align-items-center flex-wrap">
        {downloadStudentData.length === 0 ? (
          <button className="btn btn-success mx-4" disabled>
            Download CSV
          </button>
        ) : (
          <ExcelExport data={downloadStudentData} metaData={[`LIST OF CANDIDATES OF THE WALL STREET SCHOOL FOR ${companyName}`,`Source: TWSS Internal Database`]}/>
        )}
        <button
          className="btn btn-primary my-2"
          onClick={downloadResumes}
          disabled={disableDownlad}
        >
          Download Resume Zip
        </button>
      </div>
    </div>
  );
}

export default JobsFilter;
