const theme = {
  colors: {
    primary: "#2f55d4",
  },
  fonts: {
    main: '"Poppins", sans-serif',
  },
  //fontSize are in rem
  fontSizes: {
    big: "3rem", //48px
    mediumBig: "1.25rem", //20px
    medium: "1.125rem", //18px
    small: "0.875rem", //14px
    verysmall: "0.75rem", //12px
  },
  screens: {
    mobile: "768px",
  },
  navHeight: "113px",
  mobNavHeight: "76px",
};

export default theme;
