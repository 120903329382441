import React, { useState } from "react";
import { Key } from "react-feather";
import { useForm } from "react-hook-form";
import Modal from "../../components/ui/Modal";
import Spinner from "../../components/ui/Spinner";
import {
  InputWithIcon,
  MainError,
  SuccessMessage,
  Error,
} from "../../globalStyles";
import { changePassword } from "../../utilities/firebaseHelper";

function ChangePassword() {
  const [confirmPassword, setConfirmPassword] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [infoOnComplete, setInfoOnComplete] = useState(false);
  const { register, handleSubmit, errors } = useForm();

  const handleFormSubmit = async (data) => {
    if (data.newPassword !== confirmPassword) {
      setError(true);
      return;
    }
    setLoading(true);
    try {
      await changePassword(data.password,data.newPassword);
      setLoading(false);
      setInfoOnComplete("Password Changed");
      setTimeout(() => {
        setInfoOnComplete(false);
      }, 3000);
    } catch(error) {
      console.log(error)
      setLoading(false);
      setInfoOnComplete(error);
      setTimeout(() => {
        setInfoOnComplete(false);
      }, 3000);
    }
  };
  return (
    <div className="container navpad">
      <br />
      <br />
      <br />
      {loading ? (
        <div className="py-5">
          <Spinner />
        </div>
      ) : (
        <>
          <Modal show={infoOnComplete} onClose={() => setInfoOnComplete(false)}>
            {infoOnComplete === "Password Changed" ? (
              <SuccessMessage className="p-4">{infoOnComplete}</SuccessMessage>
            ) : (
              <MainError className="p-4">{infoOnComplete}</MainError>
            )}
          </Modal>
          <h4 className="card-title text-center">Change Password</h4>
          <div className="row justify-content-center">
            <form
              className="login-form mt-4 col-12 col-md-6"
              onSubmit={handleSubmit(handleFormSubmit)}
            >
              <div className="form-group position-relative">
                <label>
                  Current Password <span className="text-danger">*</span>
                </label>
                <InputWithIcon>
                  <Key className="icon" />
                  <input
                    type="password"
                    className="form-control pl-5"
                    placeholder="Password"
                    name="password"
                    ref={register({
                      required: "required",
                    })}
                  />
                  {errors.password && <Error>{errors.password.message}</Error>}
                </InputWithIcon>
              </div>
              <div className="form-group position-relative">
                <label>
                  New Password <span className="text-danger">*</span>
                </label>
                <InputWithIcon>
                  <Key className="icon" />
                  <input
                    type="password"
                    className="form-control pl-5"
                    placeholder="Password"
                    name="newPassword"
                    ref={register({
                      required: "required",
                    })}
                  />
                  {errors.newPassword && (
                    <Error>{errors.newPassword.message}</Error>
                  )}
                </InputWithIcon>
              </div>
              <div className="form-group position-relative">
                <label>
                  Confirm Password <span className="text-danger">*</span>
                </label>
                <InputWithIcon>
                  <Key className="icon" />
                  <input
                    type="password"
                    className="form-control pl-5"
                    placeholder="Password"
                    name="password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                  {error && <Error>Passwords do not match</Error>}
                </InputWithIcon>
              </div>
              <button className="btn btn-primary">Change</button>
            </form>
          </div>
        </>
      )}
    </div>
  );
}

export default ChangePassword;
