import React from "react";
import { Search } from "react-feather";

import { InputWithIcon } from "../../globalStyles";
import { debounce } from "../../utilities";

function SearchInput({ placeholder, setFilter }) {

  const handleSearch = debounce((e) => {
    setFilter(e.target.value)
  },500);

  return (
    <InputWithIcon>
      <Search className="icon" />
      <input
        type="text"
        className="form-control pl-5"
        placeholder={placeholder || "Search"}
        onChange={handleSearch}
      />
    </InputWithIcon>
  );
}

export default SearchInput;
