import React from "react";
import { Grid, MapPin } from "react-feather";

function Hero({ title, subtitle, location, company }) {
  return (
    <section className="bg-half bg-light d-table w-100">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-12 text-center">
            <div className="page-next-level">
              <h4 className="title mb-3"> {title} </h4>
              {subtitle && (
                <p className="para-desc mx-auto text-muted">{subtitle}</p>
              )}
              <ul className="list-unstyled d-flex justify-content-center align-items-center">
                {location && (
                  <li className="list-inline-item text-primary mr-3 d-flex align-items-center">
                    <MapPin color="red" className="mr-2" size={20} /> {location}
                  </li>
                )}
                {company && (
                  <li className="list-inline-item text-primary d-flex align-items-center mb-1">
                    <Grid className="mr-2" size={20} />
                    {company}
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Hero;
