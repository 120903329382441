import React, { useState } from "react";
import { Edit, Link, Trash2 } from "react-feather";
import { useHistory } from "react-router-dom";
import Switch from "react-switch";

import { copyToClipboard } from "../../utilities";
import { SuccessText } from "../../globalStyles";
import { updateJob } from "../../firestoreApi";

function JobCard({
  job,
  setLoading,
  setError,
  setJobs,
  setConfirmForJobDelete,
}) {
  const history = useHistory();
  const [show, setShow] = useState(false);
  const handleActivate = async (check) => {
    setLoading(true);
    try {
      await updateJob(job.id,{ activate: check })
      setJobs((p) => {
        const newData = [...p];
        const ind = newData.findIndex((data) => data.id === job.id);
        newData[ind].activate = check;
        return newData;
      });
      setLoading(false);
    } catch (e) {
      setError(`Unable to ${check ? "activate" : "deactivate try again"}`);
      setLoading(false);
    }
  };
  const handleJobClick = () => {
    history.push({
      pathname: "/applications_detail",
      state: job,
    });
  };
  return (
    <>
      <tr>
        <td>
          <div className="media">
            <i className="uil uil-comment text-muted h5"></i>
            <div className="content ml-3">
              <p
                onClick={handleJobClick}
                style={{ cursor: "pointer" }}
                className="forum-title text-primary font-weight-bold"
              >
                {job.jobTitle}
              </p>
              <p className="text-muted small mb-0 mt-2">
                Company:{" "}
                <p
                  onClick={handleJobClick}
                  style={{ cursor: "pointer" }}
                  className="text-dark font-weight-bold"
                >
                  {job.companyName}
                </p>
              </p>
              <p className="text-muted small mb-0 mt-2">
                Posted By : {job.postedBy}
              </p>
            </div>
          </div>
        </td>
        <td className="text-center small">
          {job.applications ? job.applications.length : 0}
        </td>
        <td className="d-flex flex-column align-items-center position-relative">
          <SuccessText topRight show={show}>
            Copied to Clipboard
          </SuccessText>
          <button
            className="btn btn-primary p-1 mb-2"
            style={{
              display: "grid",
              placeContent: "center",
            }}
            onClick={() => {
              copyToClipboard(
                `${process.env.REACT_APP_BASE_URL}/jobs/${job.id}`
              );
              setShow(true);
              setTimeout(() => setShow(false), 1500);
            }}
            data-toggle="tooltip"
            data-placement="top"
            title="copy link"
          >
            <Link size={15} />
          </button>
          <button
            className="btn btn-primary p-1 mb-2"
            style={{
              display: "grid",
              placeContent: "center",
            }}
            onClick={() =>
              history.push({
                pathname: "/add_job",
                state: { job: job },
              })
            }
            data-toggle="tooltip"
            data-placement="top"
            title="Edit"
          >
            <Edit size={15} />
          </button>
          <button
            className="btn btn-danger p-1 mb-2"
            style={{
              display: "grid",
              placeContent: "center",
            }}
            onClick={() => setConfirmForJobDelete(job)}
            data-toggle="tooltip"
            data-placement="top"
            title="Delete"
          >
            <Trash2 size={15} />
          </button>
          <label
            data-toggle="tooltip"
            data-placement="top"
            title="active/inactive"
          >
            <Switch
              checked={job.activate}
              onChange={handleActivate}
              onColor="#86d3ff"
              onHandleColor="#2693e6"
              handleDiameter={12}
              uncheckedIcon={false}
              checkedIcon={false}
              boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
              activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
              height={10}
              width={25}
              className="react-switch"
            />
          </label>
        </td>
      </tr>
    </>
  );
}

export default JobCard;
