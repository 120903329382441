import styled, { css } from "styled-components";
import { StickyTable, Cell } from "react-sticky-table";
import { ReactComponent as WhatsApp } from "../../assets/whatsapp.svg";

export const TableWrapper = styled.div`
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: #d8d8d8 0% 0% no-repeat padding-box;
    border-radius: 100px;
    margin-right: 0.3rem;
  }
`;
export const StyledTable = styled(StickyTable)`
  border: 1px solid #e5e5e5;

  & .sticky-table-row:first-child {
    background: #e5e5e5;
  }
  & .sticky-table-cell {
    border-right: 1px solid #e5e5e5;
    border-bottom: 1px solid #e5e5e5;
  }
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: #d8d8d8 0% 0% no-repeat padding-box;
    border-radius: 100px;
    margin-right: 0.3rem;
  }
`;

export const StyledCell = styled(Cell)`
  white-space: initial;
  min-width: ${({ width }) => width};
  height: ${({ height }) => height};
  max-height: ${({ height }) => height};
  ${({ header }) =>
    header &&
    css`
      background: #f4f8fb;
      font-weight: bold;
    `}
`;

export const WhatsAppIcon = styled(WhatsApp)`
  width: 20px;
`;
