import React, { useEffect, useState } from "react";

function Filters({ data, setFilteredData }) {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [showfilters, setShowFilters] = useState(false);

  useEffect(() => {
    let newFilteredData = [...data];
    if (name) {
      newFilteredData = newFilteredData.filter(
        (item) =>
          item.profile &&
          item.profile.name.toLowerCase().includes(name.toLowerCase())
      );
    }
    if (email) {
      newFilteredData = newFilteredData.filter((item) =>
        item.email.toLowerCase().includes(email.toLowerCase())
      );
    }
    if (phone) {
      newFilteredData = newFilteredData.filter(
        (item) =>
          item.profile &&
          item.profile.phoneNumber.toLowerCase().includes(phone.toLowerCase())
      );
    }
    setFilteredData(newFilteredData);
  }, [name, phone, email, data]);

  return (
    <div className="form custom-form mt-4">
      <button
        className="btn btn-secondary px-2 py-1 mb-3"
        onClick={() => setShowFilters((p) => !p)}
      >
        Filters
      </button>
      {showfilters && (
        <div className="d-flex flex-wrap py-4 bg-light">
          <div className="form-group col-12 col-md-4">
            <label className="w-100">By Name</label>
            <input
              className="form-control"
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="form-group col-12 col-md-4">
            <label className="w-100">By Phone</label>
            <input
              className="form-control"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </div>
          <div className="form-group col-12 col-md-4">
            <label className="w-100">By Email</label>
            <input
              className="form-control"
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default Filters;
