export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const SETJOBS = "SETJOBS";
export const SETUSERS = "SETUSERS";
export const LOADING = "LOADING";
export const ERROR = "ERROR";
export const LASTJOBDATE = "LASTJOBDATE";
export const JOBPAGEEND = "JOBPAGEEND";
export const INITJOBSDATA = "INITJOBSDATA";
export const NOCHANGE = "NOCHANGE";
