import { sendPasswordResetEmail } from "firebase/auth";
import React, { useState } from "react";
import { User } from "react-feather";
import { useForm } from "react-hook-form";

import Spinner from "../../components/ui/Spinner";
import { auth } from "../../firebase";
import {
  InputWithIcon,
  Error,
  MainError,
  SuccessMessage,
} from "../../globalStyles";

function ForgotPassword(props) {
  const { register, handleSubmit, errors } = useForm();
  const [error, setError] = useState(false);
  const [sent, setSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleEmailSend = (data) => {
    setLoading(true);
    var actionCodeSettings = {
      // After password reset, the user will be give the ability to go back
      // to this page.
      url: `${process.env.REACT_APP_BASE_URL}/login`,
      handleCodeInApp: false,
    };
    sendPasswordResetEmail(auth,data.email, actionCodeSettings)
      .then(() => {
        setSent(true);
        setLoading(false);
      })
      .catch((err) => {
        setError(err.message);
        setTimeout(() => setError(false), 4000);
        setLoading(false);
      });
  };

  return (
    <section className="bg-home bg-circle-gradiant d-flex align-items-center">
      <div className="bg-overlay bg-overlay-white"></div>
      <div className="container">
        {loading ? (
          <Spinner />
        ) : (
          <div className="row justify-content-center">
            <div className="col-lg-5 col-md-8">
              <div className="card login-page bg-white shadow rounded border-0">
                <div className="card-body">
                  {sent ? (
                    <SuccessMessage className="text-center">
                      We have sent an email to reset your password
                    </SuccessMessage>
                  ) : (
                    <>
                      <h4 className="card-title text-center">
                        Forgot Password
                      </h4>
                      {error && <MainError>{error}</MainError>}
                      <br />
                      <h6>
                        Please enter the Email associated with your account
                      </h6>
                      <br />
                      <div className="form-group position-relative">
                        <label>
                          Your Email <span className="text-danger">*</span>
                        </label>
                        <InputWithIcon>
                          <User className="icon" />
                          <input
                            className="form-control pl-5"
                            placeholder="Email"
                            name="email"
                            ref={register({
                              required: "email is required",
                              pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                message: "invalid email address",
                              },
                            })}
                          />
                          {errors.email && (
                            <Error>{errors.email.message}</Error>
                          )}
                        </InputWithIcon>
                      </div>
                      <p className="m-0">
                        You will recieve a link on your email.
                      </p>
                      <div className="text-md-end mt-4">
                        <button
                          className="btn btn-primary w-100"
                          onClick={handleSubmit(handleEmailSend)}
                        >
                          Send
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
}

export default ForgotPassword;
