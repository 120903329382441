import React, { useState } from "react";
import ReactTooltip from "react-tooltip";
import { Row } from "react-sticky-table";

import { StyledTable, TableWrapper, StyledCell } from "./styles";
import TableRow from "./Row";

function StudentTable({ students, setDownloadResumeZip }) {
  const [allChecked, setAllChecked] = useState(false);

  const Heading = (
    <Row>
      <StyledCell header>
        <input
          type="checkbox"
          className="mr-2"
          data-for="selectAll"
          data-tip
          onChange={(e) => setAllChecked(e.target.checked)}
        />
      </StyledCell>

      <StyledCell header scope="col">
        Name
        <ReactTooltip
          id="selectAll"
          effect="solid"
          textColor="white"
          backgroundColor="black"
        >
          <span>Select All</span>
        </ReactTooltip>
      </StyledCell>
      <StyledCell header scope="col">
        City
      </StyledCell>
      <StyledCell header scope="col">
        Professional Experience
      </StyledCell>
      <StyledCell header scope="col">
        Years of experience
      </StyledCell>
      <StyledCell header scope="col" width="500px">
        Field of experience
      </StyledCell>
      <StyledCell header scope="col">
        10th Marks
      </StyledCell>
      <StyledCell header scope="col">
        12th Marks
      </StyledCell>
      <StyledCell header scope="col">
        Gradution Marks
      </StyledCell>
      <StyledCell header scope="col">
        Last CTC
      </StyledCell>
      <StyledCell header scope="col">
        Email
      </StyledCell>
      <StyledCell header scope="col">
        Phone
      </StyledCell>
      <StyledCell header scope="col">
        Test score
      </StyledCell>
      <StyledCell header scope="col">
        Screenshot of test score
      </StyledCell>
      <StyledCell header scope="col">
        Latest College Name
      </StyledCell>
      <StyledCell header scope="col">
        Time of Application
      </StyledCell>
      <StyledCell header scope="col">
        Resume
      </StyledCell>
    </Row>
  );

  const Tablebody = (
    <>
      {students.map((item, ind) => (
        <TableRow
          key={ind}
          data={item}
          ind={ind}
          setDownloadResumeZip={setDownloadResumeZip}
          checked={allChecked}
        />
      ))}
    </>
  );

  return (
    <div>
      <TableWrapper style={{ width: "100%", height: "500px" }}>
        <StyledTable leftStickyColumnCount={2}>
          {Heading}
          {Tablebody}
        </StyledTable>
      </TableWrapper>
    </div>
  );
}

export default StudentTable;
