import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Key, User } from "react-feather";
import { useForm } from "react-hook-form";

import secondaryApp from "../../secondaryFirebase";
import {
  InputWithIcon,
  Error,
  MainError,
  SuccessMessage,
} from "../../globalStyles";
import Modal from "../../components/ui/Modal";
import Spinner from "../../components/ui/Spinner";
import sendemail from "../../utilities/sendEmail";
import { createUserWithEmailAndPassword, getAuth } from "firebase/auth";
import { addStudent } from "../../firestoreApi";

function AddStudentForm() {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const { register, handleSubmit, errors } = useForm({
    email: "",
    password: "",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleFormSubmit = async (data) => {
    setLoading(true);
    try {
      const res = await createUserWithEmailAndPassword(
        getAuth(secondaryApp),
        data.email,
        data.password
      );
      const userDetails = {
        email: res.user.email,
        id: res.user.uid,
      };
      await addStudent(userDetails);
      sendemail({
        toEmail: data.email,
        email: data.email,
        password: data.password,
      });
      setLoading(false);
      setSuccess("User Added");
      setTimeout(() => {
        setSuccess(false);
      }, 2000);
    } catch (e) {
      setLoading(false);
      setError(e.message);
      setTimeout(() => {
        setError(false);
      }, 2000);
    }
  };

  return (
    <>
      <Modal
        show={error || success}
        onClose={() => {
          setSuccess(false);
          setError(false);
        }}
      >
        {error ? (
          <MainError className="p-4">{error}</MainError>
        ) : (
          <SuccessMessage>{success}</SuccessMessage>
        )}
      </Modal>
      {loading ? (
        <div style={{ height: "100vh", width: "100vw" }}>
          <Spinner />
        </div>
      ) : (
        <div className="container navpad">
          <div className="card border-0 sidebar sticky-bar rounded shadow bg-light my-5">
            <div className="card-body">
              <form className="mt-4" onSubmit={handleSubmit(handleFormSubmit)}>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="form-group position-relative">
                      <label>
                        Your Email <span className="text-danger">*</span>
                      </label>
                      <InputWithIcon>
                        <User className="icon" />
                        <input
                          className="form-control pl-5"
                          placeholder="Email"
                          name="email"
                          ref={register({
                            required: "email is required",
                            pattern: {
                              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                              message: "invalid email address",
                            },
                          })}
                        />
                        {errors.email && <Error>{errors.email.message}</Error>}
                      </InputWithIcon>
                    </div>
                  </div>

                  <div className="col-lg-12">
                    <div className="form-group position-relative">
                      <label>
                        Password <span className="text-danger">*</span>
                      </label>
                      <InputWithIcon>
                        <Key className="icon" />
                        <input
                          type="password"
                          className="form-control pl-5"
                          placeholder="Password"
                          name="password"
                          ref={register({
                            required: "password is required",
                          })}
                        />
                        {errors.password && (
                          <Error>{errors.password.message}</Error>
                        )}
                      </InputWithIcon>
                    </div>
                  </div>
                  <div className="col-lg-12 m-0 p-0">
                    <button className="btn btn-primary m-3" type="submit">
                      Add Student
                    </button>
                    <button
                      className="btn btn-primary m-3"
                      type="button"
                      onClick={() => history.goBack()}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default AddStudentForm;
