import React from "react";
import { Check, Download, MapPin } from "react-feather";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { downloadFile } from "../../utilities/firebaseHelper";

const JobDetails = styled.ul`
  display: flex;
  flex-direction: column;
  margin: 1.5rem 0;
  & li {
    display: flex;
    align-items: flex-start;
    margin: 0.4rem 0;
  }
  & p {
    font-size: ${({ theme }) => theme.fontSizes.medium};
    margin-left: 0.3rem;
  }
`;
function StudentJobCard({ job }) {
  return (
    <div className="card job-box rounded shadow border-0 overflow-hidden" style={{ height: '100%' }}>
      <div className="border-bottom py-4 bg-light text-center">
        <h3 className="mb-0">
          <a href="page-job-company.html" className="text-dark company-name">
            {job.companyName}
          </a>
        </h3>
        <h6 className="mb-0">
          <a href="page-job-detail.html" className="text-dark">
            {job.jobTitle}
          </a>
        </h6>
      </div>

      <div className="card-body content position-relative">
        {/* <div className="firm-logo rounded-circle shadow bg-light text-center">
            <img
              src="images/job/Circleci.svg"
              className="avatar avatar-md-sm"
              alt=""
            />
          </div> */}
        <div className="company-detail text-center text-muted">
          {job.location && (
            <p className="mb-3" style={{ fontSize: "18px" }}>
              <MapPin size={20} className="mr-1" />
              Location : {job.location}
            </p>
          )}
        </div>
        <JobDetails className="job-facts list-unstyled text-muted">
          {job.minimumExperience && (
            <li className="list-inline-item">
              <Check color="green" className="mt-1" />
              <p>{job.minimumExperience} Experience</p>
            </li>
          )}
          {job.minimumQualification && (
            <li className="list-inline-item">
              <Check color="green" className="mt-1" />
              <p>{job.minimumQualification}</p>
            </li>
          )}
          {job.salary && (
            <li className="list-inline-item">
              <Check color="green" className="mt-1" />
              <p>{job.salary} Salary</p>
            </li>
          )}
        </JobDetails>
      </div>
      <div style={{ margin: '1rem', marginTop: 'auto' }}>
        <Link
          className="btn btn-outline-primary btn-block"
          to={`jobs/${job.id}`}

        >
          View Details
        </Link>
      </div>

    </div>
  );
}

export default StudentJobCard;
