import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";

import { firebaseDB } from "../../firebase";

import {
  InputWithIcon,
  Error,
  MainError,
  SuccessMessage,
} from "../../globalStyles";
import { downloadFile, handleFileUpload } from "../../utilities/firebaseHelper";
import Spinner from "../../components/ui/Spinner";
import Modal from "../../components/ui/Modal";
import { Download } from "react-feather";
import htmlToDraft from "html-to-draftjs";
import { convertToRaw, EditorState, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { child, push, ref, set } from "firebase/database";
import { addJob, updateJob } from "../../firestoreApi";

function AddJobForm({ user }) {
  const history = useHistory();
  const location = useLocation();
  const job = location.state ? location.state.job : null;
  const { register, handleSubmit, errors } = useForm({
    defaultValues: job ? { ...job } : {},
  });
  const [file, setFile] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [fileError, setFileError] = useState(false);
  const [editor, setEditor] = useState();
  const [description, setDescription] = useState();
  const [descriptionError, setDescriptionError] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (location.state && location.state.job) {
      const contentBlock = htmlToDraft(job.jobDescription);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks
        );
        const editorState = EditorState.createWithContent(contentState);
        setDescription("filled");
        setEditor(editorState);
      }
    }
  }, []);

  const uploadData = async (data) => {
    try {
      if(job) {
        await updateJob(job.id,data);
      } else {
        await addJob(data);
      }
        setLoading(false);
      setSuccess("job updated");
      setDescriptionError(false);
      setTimeout(() => {
        setSuccess(false);
      }, 2000);
    } catch(e) {
      console.error(e);
      setError("error adding job try again");
      setTimeout(() => {
        setError(false);
      }, 2000);
    }
  };
  const handleFormSubmit = async (data) => {
    // if (!url) {
    //   setFileError("pdf of the job is required");
    //   return;
    // }
    if (!description || description === "") {
      setDescriptionError("job description required");
      return;
    }

    setLoading(true);
    const jD = draftToHtml(convertToRaw(editor.getCurrentContent()));
    if (file) {
      try {
        const fileUrl = await handleFileUpload("company",file);
        if (job) {
          uploadData({
            ...data,
            updatedAt: +new Date(),
            pdfOfJob: fileUrl,
            activate: true,
            jobDescription: jD,
            postedBy: user?.profile?.name || user?.name,
          });
        } else {
          uploadData({
            ...data,
            date: +new Date(),
            pdfOfJob: fileUrl,
            activate: true,
            jobDescription: jD,
            postedBy: user?.profile?.name || user?.name,
          });
        }
        setLoading(false);
      } catch(e) {
        setError(e);
      }
    } else {
      if (job) {
        uploadData({
          ...data,
          updatedAt: +new Date(),
          activate: true,
          jobDescription: jD,
          postedBy: user?.profile?.name || user?.name,
        });
      } else {
        uploadData({
          ...data,
          date: +new Date(),
          activate: true,
          jobDescription: jD,
          postedBy: user?.profile?.name || user?.name,
        });
      }
    }
  };
  const handleChange = (file) => {
    const fileUploaded = file;
    if (fileUploaded.size / 3000000 > 1) {
      setFileError("maximum file size is 1 mb");
      return;
    }
    setFile(file);
  };

  return (
    <>
      <Modal
        show={error || success}
        onClose={() => {
          setSuccess(false);
          setError(false);
        }}
      >
        {error ? (
          <MainError className="p-4">{error}</MainError>
        ) : (
          <SuccessMessage>{success}</SuccessMessage>
        )}
      </Modal>
      <div>
        {loading ? (
          <div style={{ height: "100vh", width: "100vw" }}>
            <Spinner />
          </div>
        ) : (
          <div className="container navpad">
            <div className="card border-0 sidebar sticky-bar rounded shadow bg-light my-5">
              <div className="card-body">
                <form
                  className="mt-4"
                  onSubmit={handleSubmit(handleFormSubmit)}
                >
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="form-group position-relative">
                        <label>
                          Company Name <span className="text-danger">*</span>
                        </label>
                        <InputWithIcon>
                          <input
                            className="form-control px-3"
                            placeholder="Company Name"
                            name="companyName"
                            ref={register({
                              required: "company name is required",
                            })}
                          />
                          {errors.companyName && (
                            <Error>{errors.companyName.message}</Error>
                          )}
                        </InputWithIcon>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-group position-relative">
                        <label>
                          Job Title <span className="text-danger">*</span>
                        </label>
                        <InputWithIcon>
                          <input
                            className="form-control px-3"
                            placeholder="Job Title"
                            name="jobTitle"
                            ref={register({
                              required: "job title is required",
                            })}
                          />
                          {errors.jobTitle && (
                            <Error>{errors.jobTitle.message}</Error>
                          )}
                        </InputWithIcon>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-group position-relative">
                        <label>Salary</label>
                        <InputWithIcon>
                          <input
                            className="form-control px-3"
                            placeholder="Salary"
                            name="salary"
                            ref={register()}
                          />
                        </InputWithIcon>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-group position-relative">
                        <label>Location</label>
                        <InputWithIcon>
                          <input
                            className="form-control px-3"
                            placeholder="Location"
                            name="location"
                            ref={register()}
                          />
                        </InputWithIcon>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-group position-relative">
                        <label>Minimum Experience </label>
                        <InputWithIcon>
                          <input
                            className="form-control px-3"
                            placeholder="Minimum Experience"
                            name="minimumExperience"
                            ref={register()}
                          />
                        </InputWithIcon>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-group position-relative">
                        <label>Minimum Qualification </label>
                        <InputWithIcon>
                          <input
                            className="form-control px-3"
                            placeholder="Minimum Qualification"
                            name="minimumQualification"
                            ref={register()}
                          />
                        </InputWithIcon>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="position-relative">
                        <label style={{ fontWeight: "700", fontSize: "14px" }}>
                          Job Description <span className="text-danger">*</span>
                        </label>
                        <Editor
                          editorState={editor}
                          editorStyle={{
                            backgroundColor: "white",
                            minHeight: "250px",
                          }}
                          onEditorStateChange={(eS) => {
                            setEditor(eS);
                          }}
                          onChange={(eS) => {
                            setDescription(() => {
                              let text = "";
                              eS.blocks.forEach((block) => {
                                text = text + block.text;
                              });
                              return text;
                            });
                          }}
                        />
                        {descriptionError && <Error>{descriptionError}</Error>}
                      </div>
                    </div>
                    <div className="col-lg-12 mt-5">
                      <div className="form-group position-relative">
                        <label>Job Description File (if any)</label>
                        <InputWithIcon>
                          <input
                            type="file"
                            accept=".xlsx,.xls,.doc, .docx,.ppt, .pptx,.txt,.pdf"
                            className="form-control px-3"
                            placeholder="Pdf of the job"
                            onChange={(event) =>
                              handleChange(event.target.files[0])
                            }
                          />
                          {fileError && <Error>{fileError}</Error>}
                          {job && job.pdfOfJob && (
                            <button
                              className="btn btn-success p-1 m-2"
                              style={{
                                display: "grid",
                                placeContent: "center",
                              }}
                              type="button"
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Download"
                              onClick={() => downloadFile(job.pdfOfJob)}
                            >
                              <Download size={20} />
                            </button>
                          )}
                        </InputWithIcon>
                      </div>
                    </div>
                    <div className="col-lg-12 m-0 p-0">
                      <button className="btn btn-primary m-3">
                        {job ? "Save" : "Add the Job"}
                      </button>
                      <button
                        className="btn btn-primary m-3"
                        type="button"
                        onClick={() => history.goBack()}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, null)(AddJobForm);
