import styled from "styled-components";

export const StyledNavbar = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 1000;
  color: black;
  &.nav-colored {
    background: white;
    box-shadow: 0 0 3px rgb(60 72 88 / 15%);
  }
  &.nav-transparent {
    background: transparent;
    box-shadow: none;
  }
  transition: all 0.3s ease;
  & .icon {
    display: none;
  }
  @media (max-width: ${({ theme }) => theme.screens.mobile}) {
    & .icon {
      display: block;
    }
  }
`;

export const NavLinks = styled.div`
  @media (max-width: ${({ theme }) => theme.screens.mobile}) {
    width: 100%;
    height: ${({ show }) => (show ? "200px" : 0)};
    overflow: hidden;
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    & button {
      width: 100% !important;
    }
    transition: all 0.3s ease-in-out;
  }
`;
