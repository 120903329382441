import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { User, Key } from "react-feather";

import Spinner from "../../components/ui/Spinner";
import { InputWithIcon, Error, MainError } from "../../globalStyles";
import Modal from "../../components/ui/Modal";
import { signIn } from "../../utilities/firebaseHelper";
import { Link } from "react-router-dom";

function Login() {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const { register, handleSubmit, errors } = useForm();

  const handleFormSubmit = (data) => {
    setLoading(true);
    signIn(data, setLoading, setError);
  };

  return (
    <>
      <Modal show={error} onClose={() => setError(false)}>
        <MainError className="p-4">{error}</MainError>
      </Modal>
      <section className="bg-home bg-circle-gradiant d-flex align-items-center">
        <div className="bg-overlay bg-overlay-white"></div>
        {loading ? (
          <div style={{ height: "100vh", width: "100vw" }}>
            <Spinner />
          </div>
        ) : (
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-5 col-md-8">
                <div className="card login-page bg-white shadow rounded border-0">
                  <div className="card-body">
                    <h4 className="card-title text-center">Login</h4>
                    <form
                      className="login-form mt-4"
                      onSubmit={handleSubmit(handleFormSubmit)}
                    >
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="form-group position-relative">
                            <label>
                              Your Email <span className="text-danger">*</span>
                            </label>
                            <InputWithIcon>
                              <User className="icon" />
                              <input
                                className="form-control pl-5"
                                placeholder="Email"
                                name="email"
                                ref={register({
                                  required: "email is required",
                                  pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                    message: "invalid email address",
                                  },
                                })}
                              />
                              {errors.email && (
                                <Error>{errors.email.message}</Error>
                              )}
                            </InputWithIcon>
                          </div>
                        </div>

                        <div className="col-lg-12">
                          <div className="form-group position-relative">
                            <label>
                              Password <span className="text-danger">*</span>
                            </label>
                            <InputWithIcon>
                              <Key className="icon" />
                              <input
                                type="password"
                                className="form-control pl-5"
                                placeholder="Password"
                                name="password"
                                ref={register({
                                  required: "password is required",
                                })}
                              />
                              {errors.password && (
                                <Error>{errors.password.message}</Error>
                              )}
                            </InputWithIcon>
                          </div>
                        </div>

                        <div className="col-lg-12">
                          <div className="d-flex justify-content-end mb-3">
                            <p className="forgot-pass mb-0">
                              <Link
                                to="/forgot_password"
                                className="text-dark font-weight-bold"
                              >
                                Forgot password ?
                              </Link>
                            </p>
                          </div>
                        </div>
                        <div className="col-lg-12 mb-0">
                          <button className="btn btn-primary btn-block">
                            Sign in
                          </button>
                        </div>
                        <div className="text-center mt-3">
                          <small>
                            To view and apply for jobs, get in touch with us
                            through communication.twss@gmail.com
                          </small>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </section>
    </>
  );
}

export default Login;
