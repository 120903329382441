import React from "react";

function index() {
  return (
    <div
      className="spinner h-100 d-flex justify-content-center align-items-center"
      style={{ color: "#2f55d4" }}
    >
      <div className="spinner-grow" role="status"></div>
    </div>
  );
}

export default index;
