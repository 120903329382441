import React from "react";

function ConfirmationModal({
  onConfirm,
  onClose,
  title,
  content,
  confirmText,
  closeText,
  closeConfirm,
}) {
  return (
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title text-danger">{title}</h5>
        <button type="button" className="close" onClick={closeConfirm}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <p>{content}</p>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-danger p-2"
          onClick={onConfirm}
        >
          {confirmText}
        </button>
        <button
          type="button"
          className="btn btn-secondary p-2"
          onClick={onClose}
        >
          {closeText ? closeText : "close"}
        </button>
      </div>
    </div>
  );
}

export default ConfirmationModal;
