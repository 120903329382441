export const emailToKey = (email) => {
  //firebase do not allow ".", "#", "$", "[", or "]"
  let key = email;
  key = key.replaceAll(".", ",0,");
  key = key.replaceAll("#", ",1,");
  key = key.replaceAll("$", ",2,");
  key = key.replaceAll("[", ",3,");
  key = key.replaceAll("]", ",4,");
  return key;
};

export const keyToEmail = (key) => {
  let email = key;
  email = email.replaceAll(",0,", ".");
  email = email.replaceAll(",1,", "#");
  email = email.replaceAll(",2,", "$");
  email = email.replaceAll(",3,", "[");
  email = email.replaceAll(",4,", "]");

  return email;
};

export const copyToClipboard = (str) => {
  const el = document.createElement("textarea");
  el.value = str;
  document.body.appendChild(el);
  el.select();
  document.execCommand("copy");
  document.body.removeChild(el);
};

export const getISTTime = () => {
  var date = new Date(Date.now());
  var year = date.getUTCFullYear();
  var month = date.getUTCMonth() + 1; // getMonth() is zero-indexed, so we'll increment to get the correct month number
  var day = date.getUTCDate();
  var hours = date.getUTCHours() + 5;
  var minutes = date.getUTCMinutes() + 30;
  var seconds = date.getUTCSeconds();

  if (minutes >= 60) {
    hours = hours + 1;
    minutes = minutes - 60;
  }
  let ampm = "am";
  ampm = hours > 12 ? "pm" : "am";
  month = month < 10 ? "0" + month : month;
  day = day < 10 ? "0" + day : day;
  hours = hours > 12 ? hours - 12 : hours;
  hours = hours < 10 ? "0" + hours : hours;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  seconds = seconds < 10 ? "0" + seconds : seconds;

  return `${hours}:${minutes} ${ampm}, ${day}/${month}/${year}`;
};

export function debounce(func, wait, immediate) {
  var timeout;
  return function() {
    var context = this,
      args = arguments;
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(function() {
      timeout = null;
      if (!immediate) {
        func.apply(context, args);
      }
    }, wait);
    if (callNow) func.apply(context, args);
  }
}

export const flatProfile = (profile) => {
  if(!profile || !(typeof profile === 'object' && !Array.isArray(profile))) return {}

  let res = {};

  res['Name'] = profile.name || "";
  res['Email'] = profile.email || "";
  res["Phone Number"] = profile.phoneNumber || ""
  res["Test Score"] = profile.testScore || ""
  res["10th Marks"] = profile.marks10 || ""
  res["12th Marks"] = profile.marks12 || ""
  res["Graduation Marks"] = profile.marksGrad || ""
  res["fieldOfExperience"] = profile.fieldOfExperience?.join(", ") || ""
  res["Years Of Experience"] = profile.yearsOfExperience || ""
  res["Professional Experience"] = profile.professionalExperience?.join(", ") || ""
  res["Last CTC"] = profile.lastCTC || ""
  res["College Name"] = profile.collegeName || ""
  res["City"] = profile.city || ""
  res["Country"] = (profile.country?.name || "") + `(code: ${profile.country?.code || " "}), dial code: ${profile.country?.dial_code || ""}`
  res["Resume"] = profile.resume || ""
  res["Screenshot Of Test"] = profile.screenshotOfTest || ""

  return res;
}