import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
  Book,
  Briefcase,
  Clock,
  DollarSign,
  Download,
  Grid,
  MapPin,
  Monitor,
  Send,
} from "react-feather";
import { EditorState, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import htmlToDraft from "html-to-draftjs";

import Spinner from "../../components/ui/Spinner";
import Hero from "../../components/Hero/Hero";
import { MainError, SuccessMessage } from "../../globalStyles";
import Modal from "../../components/ui/Modal";
import ConfirmationModal from "../../components/ui/ConfirmationModal";
import { downloadFile } from "../../utilities/firebaseHelper";
import { getISTTime } from "../../utilities";
import { applyJob, checkIfJobApplied, getJob } from "../../firestoreApi";

function JobDetail({ user }) {
  const params = useParams();
  const history = useHistory();
  const id = params.id;
  const [disable, setDisable] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [success, setSucces] = useState(false);
  const [job, setJob] = useState(null);
  const [apply, setApply] = useState(false);
  const [jobDescription, setJobDescription] = useState();

  useEffect(() => {
    if (user) {
      if (!user.profile) {
        history.push("/profile");
      }
      disableApplyButtonIfApplied()
    }
  }, [user, id]);

  const disableApplyButtonIfApplied = async () => {
    try {
      let applied = await checkIfJobApplied(user.id, id);
      if (applied) setDisable("Applied");
    } catch(e) {
      setDisable("Applied");
    }
    setLoading(false);
    return true;
  }
  useEffect(() => {
    getJob(id)
      .then((job) => {
        if (!job.activate) {
          setDisable("Job not active");
        }
        setJob(job);
        const contentBlock = htmlToDraft(job.jobDescription);
        if (contentBlock) {
          const contentState = ContentState.createFromBlockArray(
            contentBlock.contentBlocks
          );
          const editorState = EditorState.createWithContent(contentState);
          setJobDescription(editorState);
        }
      })
      .catch((e) => {
        console.error(e);
        setError("No job available corresponding to the id");
        setTimeout(() => {
          setError(false);
          history.push("/jobs");
        }, 3000);
      });
  }, [id]);

  const handleApplication = () => {
    setApply(false);
    setLoading(true);
    const time = getISTTime();

    const uploadData = {
      ...user.profile,
      job,
      time,
    };
    applyJob(user.id, job.id, uploadData)
      .then(async () => {
        await disableApplyButtonIfApplied()
        setLoading(false);
        setSucces("Application sent");
        setTimeout(() => {
          setSucces(false);
        }, 2000);
      })
      .catch((e) => {
        console.error(e);
        setError("Error uploading data");
        setTimeout(() => {
          setError(false);
        }, 2000);
      });
  };
  if (loading || !job || !user || !user.profile) {
    return (
      <div className="w-100" style={{ height: "40vh" }}>
        <Spinner />
      </div>
    );
  }
  return (
    <>
      <Modal
        show={success || error || apply}
        onClose={() => {
          setError(false);
          setSucces(false);
          setApply(false);
        }}
      >
        {error && <MainError className="p-4">{error}</MainError>}
        {success && <SuccessMessage>{success}</SuccessMessage>}
        {apply && (
          <ConfirmationModal
            onConfirm={handleApplication}
            onClose={() => history.push("/profile")}
            closeConfirm={() => setApply(false)}
            title={"Alert"}
            content="Please confirm you want to share your current profile"
            closeText="Edit Profile"
            confirmText="Confirm and Apply"
          />
        )}
      </Modal>
      <div>
        <Hero
          title={job.jobTitle}
          location={job.location}
          company={job.companyName}
        />
        <section className="section">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-5 col-12">
                <div className="card sidebar sticky-bar rounded shadow border-0">
                  <div className="card-body widget border-bottom">
                    <h5 className="mb-0">Job Information</h5>
                  </div>

                  <div className="card-body">
                    {job.companyName && (
                      <div className="media widget align-items-center mt-3">
                        <Grid size={18} className="mr-3" />
                        <div className="media-body">
                          <h4 className="widget-title mb-0">Company:</h4>
                          <p className="text-primary mb-0">{job.companyName}</p>
                        </div>
                      </div>
                    )}
                    {job.location && (
                      <div className="media widget align-items-center mt-3">
                        <MapPin size={18} className="mr-3" />
                        <div className="media-body">
                          <h4 className="widget-title mb-0">Location:</h4>
                          <p className="text-primary mb-0">{job.location}</p>
                        </div>
                      </div>
                    )}
                    {job.jobTitle && (
                      <div className="media widget align-items-center mt-3">
                        <Monitor size={18} className="mr-3" />
                        <div className="media-body">
                          <h4 className="widget-title mb-0">Job Title:</h4>
                          <p className="text-primary mb-0">{job.jobTitle}</p>
                        </div>
                      </div>
                    )}
                    {job.minimumExperience && (
                      <div className="media widget align-items-center mt-3">
                        <Briefcase size={18} className="mr-3" />
                        <div className="media-body">
                          <h4 className="widget-title mb-0">
                            Minimum Experience:
                          </h4>
                          <p className="text-primary mb-0">
                            {job.minimumExperience}
                          </p>
                        </div>
                      </div>
                    )}
                    {job.minimumQualification && (
                      <div className="media widget align-items-center mt-3">
                        <Book size={18} className="mr-3" />
                        <div className="media-body">
                          <h4 className="widget-title mb-0">
                            Minimum Qualifications:
                          </h4>
                          <p className="text-primary mb-0">
                            {job.minimumQualification}
                          </p>
                        </div>
                      </div>
                    )}

                    {job.salary && (
                      <div className="media widget align-items-center mt-3">
                        <p style={{ fontSize: "1.5rem" }} className="mr-3">
                          ₹
                        </p>
                        <div className="media-body">
                          <h4 className="widget-title mb-0">Salary:</h4>
                          <p className="text-primary mb-0">{job.salary}</p>
                        </div>
                      </div>
                    )}
                    {job.date && (
                      <div className="media widget align-items-center mt-3">
                        <Clock size={18} className="mr-3" />
                        <div className="media-body">
                          <h4 className="widget-title mb-0">Date posted:</h4>
                          <p className="text-primary mb-0 mb-0">
                            {new Date(job.date).toDateString()}
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="col-lg-8 col-md-7 col-12 mt-4 pt-2 pt-sm-0">
                <div className="ml-lg-4">
                  <h5>Job Description: </h5>
                  <Editor
                    editorState={jobDescription}
                    readOnly
                    toolbarStyle={{ display: "none" }}
                    editorStyle={{
                      backgroundColor: "white",
                    }}
                  />
                  <div className="mt-4 d-flex flex-wrap">
                    <button
                      className="btn btn-outline-primary"
                      disabled={disable}
                      style={disable ? { cursor: "not-allowed" } : {}}
                      onClick={() => setApply(true)}
                    >
                      {disable ? disable : "Apply Now"}
                      <Send size={20} className="ml-1" />
                    </button>
                    {job && job.pdfOfJob && (
                      <button
                        className="btn btn-success p-1 ml-3"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "150px",
                        }}
                        type="button"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Download"
                        onClick={() => downloadFile(job.pdfOfJob)}
                      >
                        <Download size={20} className="mr-1" />
                        Attachment
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

const mapStatetoProps = (state) => ({
  user: state.user,
});
export default connect(mapStatetoProps, null)(JobDetail);
