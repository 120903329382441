import React from "react";
import { ModalWrapper, BackDrop } from "./styles";

export const Modal = ({ show, children, onClose }) => {
  return (
    <>
      <BackDrop show={show} onClick={onClose}>
        <ModalWrapper show={show} onClick={(e) => e.stopPropagation()}>
          {children}
        </ModalWrapper>
      </BackDrop>
    </>
  );
};
export default Modal;
