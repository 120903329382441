import {
  ERROR,
  LOADING,
  LOGIN,
  LOGOUT,
  SETUSERS,
} from "../actions/types";

const initState = {
  isAuthenticated: false,
  user: null,
  loading: false,
  error: false,
  userData: [],
  jobs: [],
  lastJobDate: null,
  jobPageEnd: false,
};

const reducer = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case LOGIN:
      return {
        ...state,
        isAuthenticated: true,
        user: payload,
      };
    case LOGOUT:
      return {
        ...initState,
      };
    case LOADING:
      return {
        ...state,
        loading: payload,
      };
    case ERROR:
      return {
        ...state,
        error: payload,
      };
    case SETUSERS:
      return {
        ...state,
        userData: payload,
      };
    default:
      return {
        ...state,
      };
  }
};

export default reducer;
