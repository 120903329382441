// import emailjs from "emailjs-com";

import axios from "axios";

// const sendemail = (data, templateId = "template_y4f3vso", onComplete) => {
//   emailjs
//     .send(
//       "service_2j146tv",
//       templateId,
//       data,
//       process.env.REACT_APP_EMAILJS_USERID
//     )
//     .then(
//       (result) => {
//         if (onComplete) {
//           onComplete();
//         }
//       },
//       (error) => {
//         console.log(error.text);
//       }
//     );
// };

const sendemail = (userData) => {
  let config = {
    headers: {
      accept: "application/json",
      "api-key": process.env.REACT_APP_SENDINBLUE_API_KEY,
      "content-type": "application/json",
    },
  };
  let data = {
    email: userData.email,
    attributes: {
      PLACEMENT_PASSWORD: userData.password,
    },
    listIds: [49],
    updateEnabled: true
  };
  axios.post("https://api.sendinblue.com/v3/contacts", data, config);
};
export default sendemail;
