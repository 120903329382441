import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  setDoc,
  where,
  startAfter,
  updateDoc,
  addDoc,
} from "firebase/firestore";
import { firestoreDB } from "./firebase";

const getSingleDoc = async (collectionName, docId) => {
  const docRef = doc(firestoreDB, collectionName, docId);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    return {
      ...docSnap.data(),
      id: docSnap.id
    };
  } else {
    throw Error("User does not exists");
  }
};
export const getUser = async (userId) => {
  try {
    return getSingleDoc("users", userId);
  } catch (e) {
    console.error(e);
    throw Error(e);
  }
};

export const adminPaginatedJobs = (pageSize = 12, companyName = "") => {
  let lastVisible = null;
  let jobsRef = collection(firestoreDB, "/jobs");
  let applicationsRef = collection(firestoreDB, "/applications");
  const nextPage = () => {
    return new Promise(async (resolve, reject) => {
      let q;
      if (companyName && lastVisible) {
        q = query(
          jobsRef,
          where("companyName", ">=", companyName),
          orderBy("companyName"),
          orderBy("date", "desc"),
          startAfter(lastVisible),
          limit(pageSize)
        );
      } else if (companyName) {
        q = query(
          jobsRef,
          where("companyName", ">=", companyName),
          orderBy("companyName"),
          orderBy("date", "desc"),
          limit(pageSize)
        );
      } else if (lastVisible) {
        q = query(
          jobsRef,
          orderBy("date", "desc"),
          startAfter(lastVisible),
          limit(pageSize)
        );
      } else {
        q = query(jobsRef, orderBy("date", "desc"), limit(pageSize));
      }

      try {
        const docsSnapshot = await getDocs(q);
        if (docsSnapshot.size) {
          const docs = docsSnapshot.docs;
          lastVisible = docs[docs.length - 1];
          let data = [];
          docsSnapshot.forEach(async (doc) => {
            let applications = [];
            let applicationsSnapshot = await getDocs(
              query(applicationsRef, where("id", "==", doc.id))
            );
            applicationsSnapshot.forEach((application) => {
              applications.push({
                ...application.data(),
                id: application.id,
              });
            });
            let jobData = {
              id: doc.id,
              ...doc.data(),
              applications,
            };
            data.push(jobData);
            if (data.length === docsSnapshot.size) {
              resolve(data);
            }
          });
        } else {
          resolve([])
        }
      } catch (e) {
        console.error(e);
        reject(e);
      }
    });
  };
  return nextPage;
};

export const updateJob = (jobId,patch) => {
  const jobRef = doc(firestoreDB,"jobs",jobId);
  return updateDoc(jobRef,patch);
}

export const addJob = (data) => {
  const jobRef = collection(firestoreDB,"jobs");
  return addDoc(jobRef,data);
}

export const deleteJob = (jobId) => {
  const jobRef = doc(firestoreDB,"jobs",jobId);
  return deleteDoc(jobRef)
}
export const getJob = (jobId) => {
  try {
    return getSingleDoc("jobs", jobId);
  } catch (e) {
    console.error(e);
    throw Error(e);
  }
}
export const addStudent = (data) => {
  if(data && data.id) {
    const studentRef = doc(firestoreDB,"users",data.id);
    return setDoc(studentRef,data)
  }
  
}

export const updateStudent = (studentId,patch) => {
  const stuRef = doc(firestoreDB,"users",studentId);
  return updateDoc(stuRef,patch);
}

export const getStudents = async () => {
  const usersRef = collection(firestoreDB,"users");
  const snapshot = await getDocs(usersRef);
  const users = [];
  snapshot.forEach(doc => {
    users.push({
      id:doc.id,
      ...doc.data()
    });
  })
  return users;
}

export const studentPaginatedJobs = (pageSize) => {
  let lastVisible=null;
  let jobsRef = collection(firestoreDB, "/jobs");
  const next = async () => {
    let q;
    if (lastVisible) {
      q = query(
        jobsRef,
        where("activate","==",true),
        orderBy("date", "desc"),
        startAfter(lastVisible),
        limit(pageSize)
      );
    } else {
      q = query(jobsRef,where("activate","==",true), orderBy("date", "desc"), limit(pageSize));
    }
    try {
      const docsSnapshot = await getDocs(q);
      const docs = docsSnapshot.docs;
      lastVisible = docs[docs.length - 1];
      let jobs = []
      docsSnapshot.forEach(doc => {
        jobs.push({
          ...doc.data(),
          id:doc.id,
        })
      })
      return jobs;
    } catch(e) {
      console.error(e);
      throw Error(e);
    }
  } 
  return next
}

export const getAppliedJobs = async (userId) => {
  const applicationsRef = collection(firestoreDB,'/applications');
  let q = query(applicationsRef,where("userId","==",userId));
  try {
    const docsSnapshot = await getDocs(q);
    let jobs = []
    docsSnapshot.forEach(doc => {
      jobs.push({
        ...doc.data().job,
      })
    })
    return jobs;
  } catch(e) {
    console.error(e);
    throw Error(e);
  }
}

export const applyJob = (userId,jobId,data) => {
  const doc = collection(firestoreDB,"applications");
  return addDoc(doc, {
    id:jobId,
    userId,
    ...data
  })
}

export const checkIfJobApplied = async (userId,jobId) => {
  const applicationsRef = collection(firestoreDB,'/applications');
  let q = query(applicationsRef,where("id","==",jobId),where("userId","==",userId));
  try {
    const docsSnapshot = await getDocs(q);
    if(docsSnapshot.size) 
      return true;
    return false;
  } catch(e) {
    console.error(e);
    return false;
  }
}