import React, { useState, useEffect } from "react";
import Switch from "react-switch";

import Spinner from "../../components/ui/Spinner";
import Hero from "../../components/Hero/Hero";
import Modal from "../../components/ui/Modal";
import { MainError } from "../../globalStyles";
import Filters from "./Filters";
import { getStudents, updateStudent } from "../../firestoreApi";
import ExcelExport from "../../components/ExcelExport";
import { flatProfile } from "../../utilities";

function Students() {
  const [students, setStudents] = useState([]);
  const [exportData, setExportData] = useState(null);
  const [filteredStudents, setFilteredStudents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    getStudents().then((users) => {
      setStudents(users);
      setFilteredStudents(users);
    });
  }, []);

  useEffect(() => {
    formatExportData(filteredStudents);
  }, [filteredStudents]);

  const toggleUserActiveState = async (user) => {
    setLoading(true);
    try {
      await updateStudent(user.id, { disable: !user.disable });
      const ind = filteredStudents.findIndex(
        (student) => student.id === user.id
      );
      const indUser = students.findIndex((student) => student.id === user.id);
      const newStudent = {
        ...filteredStudents[ind],
        disable: !filteredStudents[ind].disable,
      };
      const newArray = [...filteredStudents];
      const newArray2 = [...students];
      newArray2[indUser] = newStudent;
      newArray[ind] = newStudent;

      setStudents(newArray2);
      setFilteredStudents(newArray);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setError("Some Error occured please try again");
      setTimeout(() => {
        setError(false);
      }, 3000);
    }
  };

  const formatExportData = (students) => {
    let formattedData = []
    students.forEach((student) => {
      let res = flatProfile(student.profile);
      if(JSON.stringify(res) !== "{}")
        formattedData.push(res);
    });

    setExportData(formattedData);
  };
  return (
    <>
      <Modal show={loading || error} onClose={() => setError(false)}>
        {loading ? <Spinner /> : <MainError>{error}</MainError>}
      </Modal>
      <div>
        <Hero title="Student list" />
        <div className="container py-5">
          <Filters data={students} setFilteredData={setFilteredStudents} />
          <div style={{ textAlign: "right" }}>
            {exportData && <ExcelExport data={exportData} />}
          </div>

          <br />

          <span
            style={{
              fontSize: "1rem",
              fontWeight: "bold",
            }}
          >
            Showing {filteredStudents ? filteredStudents.length : 0} records out
            of {students ? students.length : 0}
          </span>
          <br />
          <table
            className="table mb-0 table-left"
            style={{ border: "1px solid #e2e2e2" }}
          >
            <thead className="bg-light">
              <tr>
                <th scope="col" className="text-left pl-4">
                  Name
                </th>
                <th scope="col" className="text-left">
                  Phone
                </th>
                <th scope="col" className="text-left">
                  Email
                </th>
                <th scope="col" className="text-center">
                  Activate/Disable
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredStudents.map((student) => (
                <tr key={student.id}>
                  <td>
                    <div className="media">
                      <i className="uil uil-comment text-muted h5"></i>
                      <div className="content ml-3">
                        <p className="forum-title text-primary font-weight-bold">
                          {student.profile?.name || "profile not complete"}
                        </p>
                      </div>
                    </div>
                  </td>
                  <td className="text-left small">
                    {student.profile?.phoneNumber || "profile not complete"}
                  </td>
                  <td className="text-left small">{student.email}</td>
                  <td className="d-flex flex-column align-items-center position-relative">
                    <label
                      data-toggle="tooltip"
                      data-placement="top"
                      title="activate/disable"
                    >
                      <Switch
                        checked={!student.disable}
                        onChange={() => toggleUserActiveState(student)}
                        onColor="#86d3ff"
                        onHandleColor="#2693e6"
                        handleDiameter={12}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                        height={10}
                        width={25}
                        className="react-switch"
                      />
                    </label>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

export default Students;
