import styled, { css } from "styled-components";

export const InputWithIcon = styled.div`
  position: relative;
  & label {
    margin-right: 0.5rem;
    display: ${({ labelFlex }) => (labelFlex ? "flex" : "inline-flex")};
    align-items: center;
  }
  & .icon {
    position: absolute;
    top: 50%;
    left: 0;
    width: 17px;
    transform: translateY(-50%);
    margin-left: 1rem;
    ${({ top }) =>
      top &&
      css`
        top: 0;
        margin-top: 1.3rem;
      `}
  }
  & input {
    font-size: 1rem !important;
    margin-right: 0.2rem;
  }
  margin-bottom: 2rem;
`;

export const Error = styled.p`
  position: absolute;
  top: 100%;
  ${({ right }) =>
    right
      ? css`
          right: 0;
        `
      : css`
          left: 0;
        `}
  right: 0;
  font-size: ${({ theme }) => theme.fontSizes.small};
  color: #bf1650;
  &:before {
    display: inline;
    content: "⚠ ";
  }
`;
export const MainError = styled.h4`
  color: #bf1650;
  font-size: ${({ theme }) => theme.fontSizes.small};

  &:before {
    display: inline;
    content: "⚠ ";
  }
`;

export const CheckBox = styled.input`
  width: 1rem;
  height: 1rem;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-print-color-adjust: exact;
  border-radius: 0.25em;
  &:focus {
    outline: none;
  }
  &:checked {
    background: ${({ theme }) => theme.colors.primary};
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
  }
`;

export const SuccessMessage = styled.p`
  padding: 2rem;
  color: green;
`;

export const SuccessText = styled.p`
  padding: 0.5rem;
  color: green;
  background: white;
  box-shadow: 0 0 3px rgb(60 72 88 / 15%);
  border-radius: 5px;
  width: ${({ width }) => width || "200px"};
  opacity: ${({ show }) => (show ? 1 : 0)};
  transition: all 0.5s ease;
  ${({ topRight }) =>
    topRight &&
    css`
      position: absolute;
      top: 0;
      right: 0;
      z-index: 100;
      transform: translateY(-70%);
    `}
`;

export const ZoomableDiv = styled.div`
  ${({ zoom }) =>
    zoom &&
    css`
      zoom: ${zoom};
      -moz-transform: scale(${zoom});
      -moz-transform-origin: 0 0;
    `}
`;

export const StyledSelect = styled.ul`
  position: absolute;
  top: 100%;
  left: 0;
  width: 300px;
  transform: translateY(-20px);
  z-index: 100;
  height: ${({ show }) => (show ? "200px" : "0")};
  overflow: ${({ show }) => (show ? "scroll" : "hidden")};
  overflow-x: hidden;
  background: white;
  border: ${({ show }) => (show ? "1px solid black" : "none")};
  & li {
    list-style: none;
    padding: 0.5rem;
    border-bottom: 1px solid black;
    cursor: pointer;
  }
`;
