import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Hero from "../../components/Hero/Hero";
import StudentJobCard from "../../components/StudentJobCard/StudentJobCard";
import { getAppliedJobs } from "../../firestoreApi";

function Applied_jobs({ user }) {
  const [jobs, setJobs] = useState([]);

  useEffect(() => {
    if(user) {
      getAppliedJobs(user.id).then((jobs) => {
        setJobs(jobs);
      }).catch(e => {
        console.error(e);
      })
    }
  }, [user]);

  return (
    <div>
      <Hero title="All Applications" />
      <br />
      <br />
      <div className="container">
        <div className="row">
          {jobs.map((job) => (
            <div key={job.id} className="col-lg-4 col-md-6 col-12 mt-4 pt-2">
              <StudentJobCard job={job} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
});
export default connect(mapStateToProps, null)(Applied_jobs);
