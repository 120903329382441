import React, { useEffect, useState } from "react";
import ReactExport from "react-data-export";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const ExcelExport = ({ data, metaData }) => {
  const [multiDataSet, setMultiDataSet] = useState();
  useEffect(() => {
    if (data?.length) {
      let widths = getMaxWidths(data);
      let columns = getColumns(data[0], widths);
      let rows = getRows(data, widths);
      let multiDataSet = [];
      if (metaData?.length) {
        metaData.forEach(title => {
          let metaData = {
            columns: [{title}],
            data: [[]],
          };
          multiDataSet.push(metaData);
        })
      }

      multiDataSet.push({
        columns,
        data: rows,
      });
      setMultiDataSet(multiDataSet);
    }
  }, [data]);

  const getMaxWidths = (data) => {
    let widths = {};
    data.forEach((item) => {
      Object.keys(item).forEach((key) => {
        if (!widths[key]) {
          widths[key] = 20;
        }
        widths[key] = Math.max(
          widths[key],
          Math.max(item[key]?.toString()?.length, key.length)
        );
      });
    });
    return widths;
  };

  const getColumns = (item, widths) => {
    return Object.keys(item).map((key) => {
      return {
        title: key,
        style: {
          fill: { patternType: "solid", fgColor: { rgb: "FFD3D3D3" } },
          font: { sz: "12" },
          border: { bottom: { style: "thin", color: { rgb: "000000" } } },
          alignment: { wrapText: true, wordBreak: true },
        },
        width: { wch: widths[key] },
      };
    });
  };
  const getRows = (data) => {
    return data.map((item) => {
      return Object.keys(item).map((key) => {
        return {
          value: `${item[key] === undefined ? "" : item[key]}`,
          style: {
            alignment: { wrapText: true },
            border: { bottom: { style: "thin", color: { rgb: "000000" } } },
          },
        };
      });
    });
  };
  return (
    <div>
      {multiDataSet ? (
        <ExcelFile
          element={
            <button className="btn btn-success mx-4">Export Excel</button>
          }
        >
          <ExcelSheet dataSet={multiDataSet} name="Organization" />
        </ExcelFile>
      ) : null}
    </div>
  );
};
export default ExcelExport;
