import {
  LOGIN,
  LOGOUT
} from "./types";
import { auth } from "../../firebase";

export const login = (user) => async (dispatch) => {
  dispatch({
    type: LOGIN,
    payload: user,
  });
};

export const logout = () => async (dispatch) => {
  auth.signOut().then(() => {
    dispatch({
      type: LOGOUT,
    });
  });
};