import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

import { NavLinks, StyledNavbar } from "./styles";
import { auth } from "../../firebase";
import { logout } from "../../store/actions";
import { Menu } from "react-feather";
import { signOut } from "firebase/auth";

function Navbar({ logout, user }) {
  const history = useHistory();
  const [showNav, setShowNav] = useState(false);
  useEffect(() => {
    const myNav = document.getElementById("mynav");
    window.onscroll = (e) => {
      if (
        document.body.scrollTop > 0 ||
        document.documentElement.scrollTop > 0
      ) {
        myNav.classList.add("nav-colored");
        myNav.classList.remove("nav-transparent");
      } else {
        myNav.classList.add("nav-transparent");
        myNav.classList.remove("nav-colored");
      }
    };
  }, []);

  const handleLogout = () => {
    signOut(auth).then(() => {
      logout();
      history.push("/login");
    })
  };

  return (
    <StyledNavbar className="py-3 d-flex justify-content-end" id="mynav">
      <div className="container d-flex justify-content-between align-items-center flex-wrap">
        <Menu
          size={22}
          className="icon mr-auto"
          onClick={() => setShowNav((p) => !p)}
        />
        <img
          className="img-fluid mr-auto"
          style={{ maxHeight: "60px", cursor: "pointer" }}
          src="https://www.thewallstreetschool.com/img/logo/logo.png"
          alt="WallStreet"
          onClick={() => history.push("/")}
        />
        <NavLinks className="mt-4 mt-md-0" show={showNav}>
          {user && user.profile && (
            <>
              <button
                className="btn btn-primary m-1"
                onClick={() => history.push("/profile")}
              >
                Edit Profile
              </button>
              <button
                className="btn btn-primary m-1"
                onClick={() => history.push("/applied_jobs")}
              >
                Applications
              </button>
            </>
          )}
          {user && user.admin && (
            <button
              className="btn btn-primary m-1"
              onClick={() => history.push("/students")}
            >
              Students
            </button>
          )}
          <button
            className="btn btn-primary m-1"
            onClick={() => history.push("/change_password")}
          >
            Change Password
          </button>
          <button className="btn btn-primary m-1" onClick={handleLogout}>
            Logout
          </button>
        </NavLinks>
      </div>
    </StyledNavbar>
  );
}
const mapStateToProps = (state) => ({
  user: state.user,
});
export default connect(mapStateToProps, { logout })(Navbar);
