import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import Spinner from "./components/ui/Spinner";
import { login, logout } from "./store/actions";
import RouteComponent from "./routes/RouteComponent";
import { auth } from "./firebase";
import Navbar from "./components/Navigation/Navbar";

import "./colors.css";
import "./bootstrap.min.css";
import "./style.css";
import "./App.css";
import Modal from "./components/ui/Modal";
import { MainError } from "./globalStyles";
import { getUser } from "./firestoreApi";

function App({ login, logout, isAuthenticated }) {
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const [disable, setDisable] = useState(false);

  useEffect(() => {
    const authSubscriber = auth.onAuthStateChanged((user) => {
      if (user) {
        getUser(user.uid).then(userDetails => {
          if (userDetails.disable) {
            setDisable(true);
          }
          login(userDetails);
          setLoading(false);
        }).catch(e => {
          setLoading(false);
        })
      } else {
        history.push("/login");
        setLoading(false);
      }
    });
    return authSubscriber
  }, [login, history]);

  if (disable) {
    return (
      <Modal show={disable} onClose={logout}>
        <MainError className="p-4">
          You account has been disabled, Please reach out at twss.help@gmail.com
          to renable it
        </MainError>
      </Modal>
    );
  }
  return (
    <div className="App">
      {loading ? (
        <div style={{ height: "100vh" }}>
          <Spinner />
        </div>
      ) : (
        <>
          {isAuthenticated && <Navbar />}
          <RouteComponent />
        </>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.isAuthenticated,
});

export default connect(mapStateToProps, { login, logout })(App);
