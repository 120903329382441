import Jobs from "../container/Jobs/Jobs";
import AdminView from "../container/AdminView/AdminView";
import ApplicationsDetail from "../container/ApplicationsDetail/ApplicationsDetail";
import Login from "../container/Login/Login";
import JobDetail from "../container/JobDetail/JobDetail";
import AddStudentForm from "../container/AddStudentForm/AddStudentForm";
import AddJobForm from "../container/AddJobForm/AddJobForm";
import StudentProfileForm from "../container/StudentProfileForm/StudentProfileForm";
import ForgotPassword from "../container/ForgotPassword/ForgotPassword";
import ChangePassword from "../container/ChangePassword/ChangePassword";
import Students from "../container/Students/Students";
import AppliedJobs from "../container/AppliedJobs/AppliedJobs";

export const routes = [
  {
    name: "Login",
    component: Login,
    private: false,
    path: "/login",
    exact: true,
    hideonauth: true,
  },
  {
    name: "Forgot Password",
    component: ForgotPassword,
    private: false,
    path: "/forgot_password",
    exact: true,
    hideonauth: true,
  },
  {
    name: "Admin View",
    component: AdminView,
    private: true,
    admin: true,
    path: "/admin_view",
    exact: true,
  },
  {
    name: "Students",
    component: Students,
    private: true,
    admin: true,
    path: "/students",
    exact: true,
  },
  {
    name: "Applications Detail",
    component: ApplicationsDetail,
    private: true,
    admin: true,
    path: "/applications_detail",
    exact: true,
  },
  {
    name: "Change Password",
    component: ChangePassword,
    private: true,
    path: "/change_password",
    exact: true,
  },
  {
    name: "Applied Jobs",
    component: AppliedJobs,
    private: true,
    path: "/applied_jobs",
    exact: true,
  },
  {
    name: "Add Student",
    component: AddStudentForm,
    private: true,
    admin: true,
    path: "/add_student",
    exact: true,
  },
  {
    name: "Add Job",
    component: AddJobForm,
    private: true,
    admin: true,
    path: "/add_job",
    exact: true,
  },
  {
    name: "Jobs",
    component: Jobs,
    private: true,
    path: "/jobs",
    exact: true,
  },
  {
    name: "Profile",
    component: StudentProfileForm,
    private: true,
    path: "/profile",
    exact: true,
  },
  {
    name: "Job Detail",
    component: JobDetail,
    private: true,
    path: "/jobs/:id",
    exact: true,
  },
];
