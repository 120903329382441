import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import * as JSZip from "jszip";
import { saveAs } from "file-saver";
import Filters from "../../components/Filters/Filters";
import Hero from "../../components/Hero/Hero";
import StudentTable from "../../components/StudentTable/StudentTable";
import { ZoomableDiv } from "../../globalStyles";

function ApplicationsDetail(props) {
  const location = useLocation();
  const history = useHistory();
  const [filteredStudents, setFilteredStudents] = useState();
  const job = location ? location.state : null
  const [downloadResumeZip, setDownloadResumeZip] = useState([]);

  if (!job) {
    history.push("/admin_view");
  }
  useEffect(() => {
    if (location.state && location.state.applications) {
      setFilteredStudents(location.state.applications);
    }
  }, [location]);
  const downloadResumes = () => {
    const zipFile = new JSZip();
    let count = 0;

    downloadResumeZip.forEach((file) => {
      const xhr = new XMLHttpRequest();
      xhr.responseType = "blob";
      xhr.onload = function () {
        const blob = xhr.response;
        let fileType = ".pdf";

        switch (blob.type) {
          case "application/msword":
            fileType = ".doc";
            break;
          case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
            fileType = ".docx";
            break;
          default:
            fileType = ".pdf";
        }
        zipFile.file(file.name + fileType, blob, {
          binary: true,
        });
        count++;
        if (count === downloadResumeZip.length) {
          zipFile.generateAsync({ type: "blob" }).then((content) => {
            saveAs(content, "resumes" + ".zip");
          });
        }
      };
      xhr.open("GET", file.resume);
      xhr.send();
    });
  };
  return (
    <ZoomableDiv zoom="0.9">
      <Hero
        title={`All applicants for ${job.companyName}'s ${job.jobTitle} position`}
      />
      <div className="largeContainer my-4">
        <Filters
          companyName={job.companyName}
          data={job.applications}
          setFilteredData={setFilteredStudents}
          downloadResumes={downloadResumes}
          disableDownlad={downloadResumeZip.length === 0}
        />
        <br />
        <span
          style={{
            fontSize: "1.2rem",
            fontWeight: "bold",
          }}
        >
          Showing {filteredStudents ? filteredStudents.length : 0} records out
          of {job.applications ? job.applications.length : 0}
        </span>
        <br />
        {filteredStudents && filteredStudents.length ? (
          <StudentTable
            students={filteredStudents}
            setDownloadResumeZip={setDownloadResumeZip}
          />
        ) : (
          <p className="w-100 text-center" style={{ height: "400px" }}>
            No data
          </p>
        )}
      </div>
    </ZoomableDiv>
  );
}

export default ApplicationsDetail;
