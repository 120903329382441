import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";

import InfiniteScrollSpinner from "../../components/ui/InfiniteScrollSpinner";
import Hero from "../../components/Hero/Hero";
import StudentJobCard from "../../components/StudentJobCard/StudentJobCard";
import { studentPaginatedJobs } from "../../firestoreApi";

function Jobs({ user }) {
  const [jobs, setJobs] = useState([]);
  const [pageEnd,setPageEnd] = useState(false);
  const [handleNext, setHandleNext] = useState();
  const history = useHistory();
  const dataperpage = 12;

  useEffect(() => {
    getNextPageHandle();
  }, []);

  useEffect(() => {
    if (handleNext) {
      handlePageEnd(true);
    }
  }, [handleNext]);

  const getNextPageHandle = () => {
    const next = studentPaginatedJobs(dataperpage);
    setHandleNext(() => next);
  };

  const handlePageEnd = async (restart) => {
    try {
      let data = await handleNext();
      if (data.length < dataperpage) {
        setPageEnd(true);
      }
      if (restart) {
        setJobs(data);
      } else {
        setJobs([...jobs, ...data]);
      }
    } catch(e) {
      console.error(e);
    }
  };

  if (!user || !user.profile) {
    history.push("/profile");
  }

  return (
    <div>
      <Hero title="All Companies" />
      <br />
      <br />

      <div className="container py-5" style={{ minHeight: "70vh" }}>
        <InfiniteScroll
          dataLength={jobs.length}
          next={handlePageEnd}
          hasMore={!pageEnd}
          className="row mb-5"
          loader={
            <div className="w-100 text-center py-3">
              <InfiniteScrollSpinner />
            </div>
          }
          endMessage={
            <p
              className="w-100 text-center py-3"
              style={{ color: "lightgrey", fontSize: "14px" }}
            >
              <b>No more jobs to show</b>
            </p>
          }
        >
          {jobs.map((job) => (
            <div key={job.id} className="col-lg-4 col-md-6 col-12 mt-4 pt-2">
              <StudentJobCard job={job} />
            </div>
          ))}
        </InfiniteScroll>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  user: state.user
});
export default connect(mapStateToProps, {  })(Jobs);
