import React, { useEffect, useState } from "react";
import { Download } from "react-feather";
import { Row, Cell } from "react-sticky-table";
import { downloadFile } from "../../utilities/firebaseHelper";
import { StyledCell, WhatsAppIcon } from "./styles";

function TableRow({ data, setDownloadResumeZip, checked }) {
  const [cellChecked, setCellChecked] = useState(false);

  useEffect(() => {
    handleRowSelect(checked);
  }, [checked]);

  const handleRowSelect = (checked) => {
    setCellChecked(checked);
    if (!checked) {
      setDownloadResumeZip((p) => {
        const filtered = p.filter((item) => item.resume !== data.resume);
        return filtered;
      });
    } else {
      setDownloadResumeZip((p) => {
        const added = [...p];
        added.push({
          name: data.name,
          resume: data.resume,
        });
        return added;
      });
    }
  };
  return (
    <>
      <Row>
        <Cell>
          <input
            type="checkbox"
            className="mr-2 name"
            checked={cellChecked}
            onChange={(e) => handleRowSelect(e.target.checked)}
          />
        </Cell>
        <Cell>
          {data.phoneNumber ? (
            <a
              className="mr-2"
              target="_blank"
              href={`//api.whatsapp.com/send?phone=${
                data.country
                  ? data.country.dial_code + data.phoneNumber
                  : "+91" + data.phoneNumber
              }&text=`}
            >
              <WhatsAppIcon />
            </a>
          ) : (
            <p>NA</p>
          )}
          {data.name ? data.name : "not provided"}
        </Cell>
        <StyledCell>{data.city ? data.city : "not provided"}</StyledCell>
        <StyledCell>
          {data.professionalExperience
            ? data.professionalExperience.join(", ")
            : "not provided"}
        </StyledCell>
        <StyledCell>
          {data.yearsOfExperience ? data.yearsOfExperience : "not provided"}
        </StyledCell>
        <StyledCell height="100px">
          {data.fieldOfExperience
            ? data.fieldOfExperience.join(", ")
            : "not provided"}
        </StyledCell>
        <StyledCell>{data.marks10 ? data.marks10 : "not provided"}</StyledCell>
        <StyledCell>{data.marks12 ? data.marks12 : "not provided"}</StyledCell>
        <StyledCell>
          {data.marksGrad ? data.marksGrad : "not provided"}
        </StyledCell>
        <StyledCell>{data.lastCTC ? data.lastCTC : "not provided"}</StyledCell>
        <StyledCell>{data.email ? data.email : "not provided"}</StyledCell>
        <StyledCell>
          {data.phoneNumber ? data.phoneNumber : "not provided"}
        </StyledCell>

        <StyledCell>
          {data.testScore ? data.testScore : "not provided"}
        </StyledCell>
        <StyledCell>
          <button
            className="btn btn-success p-1 m-2"
            style={{
              display: "grid",
              placeContent: "center",
            }}
            type="button"
            data-toggle="tooltip"
            data-placement="top"
            title="Download"
            onClick={() => downloadFile(data.screenshotOfTest)}
          >
            <Download size={20} />
          </button>
        </StyledCell>
        <StyledCell>
          {data.collegeName ? data.collegeName : "not provided"}
        </StyledCell>
        <StyledCell>{data.time ? data.time : "not provided"}</StyledCell>
        <StyledCell>
          <button
            className="btn btn-success p-1 m-2"
            style={{
              display: "grid",
              placeContent: "center",
            }}
            type="button"
            data-toggle="tooltip"
            data-placement="top"
            title="Download"
            onClick={() => downloadFile(data.resume, data.name)}
          >
            <Download size={20} />
          </button>
        </StyledCell>
      </Row>
    </>
  );
}

export default TableRow;
