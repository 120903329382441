import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  BarChart,
  Book,
  ChevronDown,
  Download,
  Edit,
  Grid,
  Info,
  Mail,
  Phone,
  User,
} from "react-feather";
import { useForm } from "react-hook-form";
import ReactTooltip from "react-tooltip";
import Spinner from "../../components/ui/Spinner";
import {
  InputWithIcon,
  Error,
  MainError,
  StyledSelect,
} from "../../globalStyles";
import { downloadFile, handleFileUpload } from "../../utilities/firebaseHelper";
import Hero from "../../components/Hero/Hero";
import Modal from "../../components/ui/Modal";
import { phoneCodes } from "../../utilities/countryPhoneCode";
import { updateStudent } from "../../firestoreApi";

function StudentProfileForm({ user }) {
  const history = useHistory();
  const { register, handleSubmit, errors } = useForm({
    mode: "onChange",
    defaultValues: user.profile
      ? {
          ...user.profile,
          resume: null,
          screenshotOfTest: null,
        }
      : {},
  });

  const [country, setCountry] = useState(
    user && user.profile && user.profile.country
      ? user.profile.country
      : {
          name: "India",
          dial_code: "+91",
          code: "IN",
        }
  );
  const [filteredPhoneCodes, setFilteredPhoneCodes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSucces] = useState(false);
  const [screenshotError, setScreenshotError] = useState(false);
  const [testScoreError,setTestScoreError] = useState(false);
  const [search, setSearch] = useState("");
  const [showCountry, setShowCountry] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    setFilteredPhoneCodes(phoneCodes);
  }, []);

  const handleFormSubmit = async (formData) => {
    let ssRequired = user && user.profile && user.profile.screenshotOfTest;
    if (!(user && user.profile && user.profile.screenshotOfTest)) {
      if (formData.testScore) {
        ssRequired = true;
      }
      if (formData.testScore && !formData.screenshotOfTest.length) {
        setScreenshotError("required");
        return;
      }
      if(formData.screenshotOfTest.length && !formData.testScore) {
        setTestScoreError("required");
        return;
      }
    }
    let resumeUrl = user && user.profile && user.profile.resume;
    let sstUrl = user && user.profile && user.profile.screenshotOfTest;
    setLoading(true);
    if (formData.resume && formData.resume[0]) {
      try {
        resumeUrl = await handleFileUpload("resumes", formData.resume[0]);
      } catch (e) {
        console.error(e)
        setError(e);
      }
    }
    if (formData.screenshotOfTest && formData.screenshotOfTest[0]) {
      try {
        sstUrl = await handleFileUpload(
          "testImages",
          formData.screenshotOfTest[0]
        );
      } catch (e) {
        console.error(e)
        setError(e);
      }
    }

    if (
      ((ssRequired && sstUrl) || (!ssRequired && !sstUrl)) &&
      resumeUrl &&
      formData &&
      user
    ) {
      // Deleting File list before uploading to DB
      delete formData.resume
      delete formData.screenshotOfTest
      const uploadData = {
        ...formData,
        resume: resumeUrl,
        country,
      };

      if (sstUrl) {
        uploadData["screenshotOfTest"] = sstUrl;
      } 
      try {
        await updateStudent(user.id, { profile: uploadData });
        setLoading(false);
        setSucces("Profile Updated");
        setTimeout(() => {
          setSucces(false);
          window.location.pathname = "/"
        }, 2000);
      } catch (err) {
        console.error(err);
        setLoading(false);
        setError("Error uploading data");
        setTimeout(() => {
          setError(false);
        }, 2000);
      }
    } else {
      setError('Unable to update, please recheck form');
      setLoading(false);
    }
  };

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearch(value);
    let newCodes = phoneCodes;
    if (value) {
      newCodes = phoneCodes.filter((country) =>
        country.name.toLowerCase().startsWith(value.toLowerCase())
      );
    }
    setFilteredPhoneCodes(newCodes);
  };
  return (
    <>
      <Modal
        show={success || error}
        onClose={() => {
          setError(false);
          setSucces(false);
        }}
      >
        {error ? (
          <MainError className="p-4">{error}</MainError>
        ) : (
          <p className="p-4" style={{ color: "#00FF00" }}>
            {success}
          </p>
        )}
      </Modal>
      <div>
        <Hero title="Job Apply Form" />
        <section className="section">
          <div className="container">
            {loading ? (
              <Spinner />
            ) : (
              <div className="row justify-content-center">
                <div className="col-12" style={{ maxWidth: "850px" }}>
                  <div className="card custom-form border-0">
                    <div className="card-body">
                      <form
                        className="rounded shadow p-4"
                        onSubmit={handleSubmit(handleFormSubmit)}
                      >
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group position-relative">
                              <label>
                                Your Name :
                                <span className="text-danger">*</span>
                              </label>
                              <InputWithIcon>
                                <User className="icon" />
                                <input
                                  name="name"
                                  type="text"
                                  className="form-control pl-5"
                                  ref={register({ required: "required" })}
                                />
                                {errors.name && (
                                  <Error>{errors.name.message}</Error>
                                )}
                              </InputWithIcon>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group position-relative">
                              <label>
                                Your Email :
                                <span className="text-danger">*</span>
                              </label>
                              <InputWithIcon>
                                <Mail className="icon" />
                                <input
                                  name="email"
                                  type="email"
                                  className="form-control pl-5"
                                  ref={register({
                                    required: "required",
                                    pattern: {
                                      value:
                                        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                      message: "invalid email address",
                                    },
                                  })}
                                />
                              </InputWithIcon>
                              {errors.email && (
                                <Error>{errors.email.message}</Error>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group position-relative">
                              <label>
                                Your WhatsApp No. :
                                <span className="text-danger">*</span>
                              </label>
                              <StyledSelect show={showCountry}>
                                <div className="p-2">
                                  <input
                                    className="search form-control"
                                    placeholder="search"
                                    value={search}
                                    onChange={handleSearch}
                                    style={{ fontSize: "1rem" }}
                                  />
                                </div>
                                {filteredPhoneCodes.map((country) => (
                                  <li
                                    key={country.name}
                                    onClick={() => {
                                      setCountry(country);
                                      setShowCountry(false);
                                    }}
                                  >
                                    {country.name} , {country.dial_code}
                                  </li>
                                ))}
                              </StyledSelect>
                              <div className="d-flex justify-content-between align-items-center">
                                <div
                                  style={{
                                    width: "18%",
                                    height: "100%",
                                    marginBottom: "2rem",
                                    cursor: "pointer",
                                  }}
                                  className="d-flex align-items-center justify-content-between form-control"
                                  onClick={() => setShowCountry((p) => !p)}
                                >
                                  <p>{country.code}</p>
                                  <ChevronDown />
                                </div>
                                <InputWithIcon style={{ width: "80%" }}>
                                  <span className="icon d-flex align-items-center">
                                    {country.dial_code}
                                  </span>
                                  <input
                                    name="phoneNumber"
                                    className="form-control ml-2 pl-5"
                                    ref={register({
                                      required: "required",
                                      minLength: {
                                        value: "4",
                                        message:
                                          "minimum length of 4 is allowed",
                                      },
                                      maxLength: {
                                        value: "12",
                                        message:
                                          "maximum length of 12 is allowed",
                                      },
                                    })}
                                  />
                                  {errors.phoneNumber && (
                                    <Error>{errors.phoneNumber.message}</Error>
                                  )}
                                </InputWithIcon>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group position-relative">
                              <label>
                                Years of experience :{" "}
                                <span className="text-danger">*</span>
                                <Info
                                  size={20}
                                  data-tip
                                  data-for="yearOfExp"
                                  className="ml-1"
                                />
                              </label>
                              <InputWithIcon>
                                <Book className="icon" />
                                <input
                                  name="yearsOfExperience"
                                  className="form-control pl-5"
                                  ref={register({
                                    required: "required",
                                    pattern: {
                                      value: /^[0-9]\d*(\.\d+)?$/,
                                      message: "Please enter a valid number",
                                    },
                                  })}
                                />
                                {errors.yearsOfExperience && (
                                  <Error>
                                    {errors.yearsOfExperience.message}
                                  </Error>
                                )}
                                <ReactTooltip
                                  id="yearOfExp"
                                  type="warning"
                                  effect="solid"
                                  textColor="white"
                                  backgroundColor="black"
                                >
                                  <span>
                                    Add how many years of experience you have
                                    had. <br />
                                    For example, If you’ve had 2 years and 6
                                    months of experience just type 2.5 in the
                                    field
                                  </span>
                                </ReactTooltip>
                              </InputWithIcon>
                            </div>
                          </div>
                          <div className="col-12">
                            <div className="form-group position-relative">
                              <label>Professional Experience :</label>
                              <InputWithIcon className="d-flex">
                                <label>
                                  <input
                                    type="checkbox"
                                    value="CA"
                                    name="professionalExperience"
                                    ref={register()}
                                  />
                                  CA
                                </label>
                                <label>
                                  <input
                                    type="checkbox"
                                    value="CA Inter"
                                    name="professionalExperience"
                                    ref={register()}
                                  />
                                  CA Inter
                                </label>
                                <label>
                                  <input
                                    type="checkbox"
                                    value="CFA Level 1"
                                    name="professionalExperience"
                                    ref={register()}
                                  />
                                  CFA Level 1
                                </label>
                                <label>
                                  <input
                                    type="checkbox"
                                    value="CFA Level 2"
                                    name="professionalExperience"
                                    ref={register()}
                                  />
                                  CFA Level 2
                                </label>
                                <label>
                                  <input
                                    type="checkbox"
                                    value="CFA Level 3"
                                    name="professionalExperience"
                                    ref={register()}
                                  />
                                  CFA Level 3
                                </label>
                                <label>
                                  <input
                                    type="checkbox"
                                    value="FRM Level 1"
                                    name="professionalExperience"
                                    ref={register()}
                                  />
                                  FRM Level 1
                                </label>
                                <label>
                                  <input
                                    type="checkbox"
                                    value="FRM Level 2"
                                    name="professionalExperience"
                                    ref={register()}
                                  />
                                  FRM Level 2
                                </label>
                                <label>
                                  <input
                                    type="checkbox"
                                    value="MBA"
                                    name="professionalExperience"
                                    ref={register()}
                                  />
                                  MBA
                                </label>
                                <ReactTooltip
                                  id="CFA"
                                  type="warning"
                                  effect="solid"
                                  textColor="white"
                                  backgroundColor="black"
                                >
                                  <span>
                                    Tick if you’ve cleared any level of CFA
                                    (Just Mention the levels in your CV/Resume)
                                  </span>
                                </ReactTooltip>
                              </InputWithIcon>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group position-relative">
                              <label>
                                City : <span className="text-danger">*</span>
                              </label>
                              <InputWithIcon>
                                <BarChart className="icon" />
                                <input
                                  name="city"
                                  className="form-control pl-5"
                                  ref={register({ required: "required" })}
                                />
                                {errors.city && (
                                  <Error>{errors.city.message}</Error>
                                )}
                              </InputWithIcon>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group position-relative">
                              <label>
                                Field of experience :
                                <span className="text-danger">*</span>
                              </label>
                              <InputWithIcon labelFlex>
                                <label>
                                  <input
                                    type="checkbox"
                                    value="Audit, Taxation or Accountancy"
                                    name="fieldOfExperience"
                                    ref={register({ required: true })}
                                  />
                                  Audit, Taxation or Accountancy
                                </label>
                                <label>
                                  <input
                                    type="checkbox"
                                    value="Sales, Marketing, Business Development"
                                    name="fieldOfExperience"
                                    ref={register({ required: true })}
                                  />
                                  Sales, Marketing, Business Development
                                </label>
                                <label>
                                  <input
                                    type="checkbox"
                                    value="IT or Engineering"
                                    name="fieldOfExperience"
                                    ref={register({ required: true })}
                                  />
                                  IT or Engineering
                                </label>
                                <label>
                                  <input
                                    type="checkbox"
                                    value="Valuation, Credit, Financial Modeling, Corporate Finance or other Core Finance Fields"
                                    name="fieldOfExperience"
                                    ref={register({ required: true })}
                                  />
                                  Valuation, Credit, Financial Modeling,
                                  Corporate Finance or other Core Finance Fields
                                </label>
                                <label>
                                  <input
                                    type="checkbox"
                                    value="No Experience"
                                    name="fieldOfExperience"
                                    ref={register({ required: true })}
                                  />
                                  No Experience
                                </label>
                                <label>
                                  <input
                                    type="checkbox"
                                    value="Others"
                                    name="fieldOfExperience"
                                    ref={register({ required: true })}
                                  />
                                  Others
                                </label>
                                {errors.fieldOfExperience && (
                                  <Error>required</Error>
                                )}
                              </InputWithIcon>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-group position-relative">
                              <label>
                                Our Assessment Test Score (Clearing our test
                                increases the probability of getting selected in
                                the job interviews) :
                              </label>
                              <InputWithIcon>
                                <Edit className="icon" />
                                <input
                                  name="testScore"
                                  className="form-control pl-5"
                                  ref={register({
                                    pattern: {
                                      value: /^[1-9]\d*(\.\d+)?$/,
                                      message: "Please enter a valid number",
                                    },
                                  })}
                                />
                                {(testScoreError || errors.testScore) && (
                                  <Error>{testScoreError || errors.testScore.message}</Error>
                                )}
                              </InputWithIcon>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group position-relative">
                              <label>Screenshot of test score :</label>
                              <InputWithIcon>
                                <input
                                  type="file"
                                  accept="image/*"
                                  className="form-control-file"
                                  name="screenshotOfTest"
                                  ref={register({
                                    validate: (file) => {
                                      if (file && file[0]) {
                                        return file[0].size < 3000000;
                                      }
                                      return true;
                                    },
                                  })}
                                />
                                {(errors.screenshotOfTest ||
                                  screenshotError) && (
                                  <Error>
                                    {screenshotError
                                      ? screenshotError
                                      : "max 3mb is allowed"}
                                  </Error>
                                )}
                                <div className="d-flex align-items-center">
                                  {user &&
                                    user.profile &&
                                    user.profile.screenshotOfTest && (
                                      <div>
                                        <button
                                          className="btn btn-success p-1 mr-2 mt-2"
                                          style={{
                                            display: "grid",
                                            placeContent: "center",
                                          }}
                                          type="button"
                                          data-for="downloadSS"
                                          data-tip
                                          onClick={() =>
                                            downloadFile(
                                              user.profile.screenshotOfTest
                                            )
                                          }
                                        >
                                          <Download size={20} />
                                        </button>
                                      </div>
                                    )}
                                  <ReactTooltip
                                    id="downloadSS"
                                    effect="solid"
                                    textColor="white"
                                    backgroundColor="black"
                                  >
                                    <span>Download</span>
                                  </ReactTooltip>
                                  <small
                                    style={{
                                      textDecoration: "underline",
                                      cursor: "pointer",
                                    }}
                                    onClick={() =>
                                      downloadFile(
                                        "https://firebasestorage.googleapis.com/v0/b/jobportal-596b3.appspot.com/o/example.png?alt=media&token=e44dc3f1-71fc-448e-b1b9-50afc3448c8c"
                                      )
                                    }
                                  >
                                    Download Example Screenshot
                                  </small>
                                </div>
                              </InputWithIcon>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group position-relative">
                              <label>
                                Last CTC :{" "}
                                <Info
                                  size={20}
                                  data-tip
                                  data-for="lastCTC"
                                  className="ml-1"
                                />
                              </label>

                              <InputWithIcon>
                                <p
                                  style={{ fontSize: "1.5rem" }}
                                  className="mr-3 icon"
                                >
                                  ₹
                                </p>
                                <input
                                  name="lastCTC"
                                  className="form-control pl-5"
                                  ref={register({
                                    pattern: {
                                      value: /^[1-9]\d*(\.\d+)?$/,
                                      message: "Please enter a valid number",
                                    },
                                  })}
                                />
                                {errors.lastCTC && (
                                  <Error>{errors.lastCTC.message}</Error>
                                )}
                                <ReactTooltip
                                  id="lastCTC"
                                  type="warning"
                                  effect="solid"
                                  textColor="white"
                                  backgroundColor="black"
                                >
                                  <span>
                                    Add the yearly CTC at your last/current job,
                                    fixed + variable <br />
                                    For Example, if it was 5.4 Lakh Per Annum
                                    just type 5.4 in the field
                                  </span>
                                </ReactTooltip>
                              </InputWithIcon>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group position-relative">
                              <label>Latest College Name :</label>
                              <InputWithIcon>
                                <Grid className="icon" />
                                <input
                                  name="collegeName"
                                  type="text"
                                  className="form-control pl-5"
                                  ref={register()}
                                />
                              </InputWithIcon>
                            </div>
                          </div>
                          <div className="col-12 col-md-6">
                            <div className="form-group position-relative">
                              <label>
                                10th Marks (percentage) :{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <InputWithIcon>
                                <Book className="icon" />
                                <input
                                  name="marks10"
                                  className="form-control pl-5"
                                  ref={register({
                                    required: "required",
                                    pattern: {
                                      value: /^[0-9]\d*(\.\d+)?$/,
                                      message: "Please enter a valid number",
                                    },
                                  })}
                                />
                                {errors.marks10 && (
                                  <Error>{errors.marks10.message}</Error>
                                )}
                              </InputWithIcon>
                            </div>
                          </div>
                          <div className="col-12 col-md-6">
                            <div className="form-group position-relative">
                              <label>
                                12th Marks (percentage) :{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <InputWithIcon>
                                <Book className="icon" />
                                <input
                                  name="marks12"
                                  className="form-control pl-5"
                                  ref={register({
                                    required: "required",
                                    pattern: {
                                      value: /^[0-9]\d*(\.\d+)?$/,
                                      message: "Please enter a valid number",
                                    },
                                  })}
                                />
                                {errors.marks12 && (
                                  <Error>{errors.marks12.message}</Error>
                                )}
                              </InputWithIcon>
                            </div>
                          </div>
                          <div className="col-12 col-md-6">
                            <div className="form-group position-relative">
                              <label>
                                Grad Marks :{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <InputWithIcon>
                                <Book className="icon" />
                                <input
                                  name="marksGrad"
                                  className="form-control pl-5"
                                  ref={register({
                                    required: "required",
                                    pattern: {
                                      value: /^[0-9]\d*(\.\d+)?$/,
                                      message: "Please enter a valid number",
                                    },
                                  })}
                                />
                                {errors.marksGrad && (
                                  <Error>{errors.marksGrad.message}</Error>
                                )}
                              </InputWithIcon>
                            </div>
                          </div>
                          <div className="col-12 col-md-6">
                            <div className="form-group position-relative">
                              <label>
                                Upload Your Cv / Resume :
                                <span className="text-danger">*</span>
                              </label>
                              <InputWithIcon>
                                <input
                                  type="file"
                                  className="form-control-file"
                                  accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                  name="resume"
                                  ref={register({
                                    required:
                                      user &&
                                      user.profile &&
                                      user.profile.resume
                                        ? false
                                        : "required",
                                    validate: (file) => {
                                      if (file && file[0]) {
                                        return file[0].size < 3000000;
                                      }
                                      return true;
                                    },
                                  })}
                                />
                                {errors.resume && (
                                  <Error>
                                    {errors.resume.message ||
                                      "max 3mb is allowed"}
                                  </Error>
                                )}
                                {user && user.profile && user.profile.resume && (
                                  <button
                                    className="btn btn-success p-1 mr-2 mt-2"
                                    style={{
                                      display: "grid",
                                      placeContent: "center",
                                    }}
                                    type="button"
                                    data-for="download"
                                    data-tip
                                    onClick={() =>
                                      downloadFile(
                                        user.profile.resume,
                                        user.profile.name
                                      )
                                    }
                                  >
                                    <Download size={20} />
                                  </button>
                                )}
                                <ReactTooltip
                                  id="download"
                                  effect="solid"
                                  textColor="white"
                                  backgroundColor="black"
                                >
                                  <span>Download</span>
                                </ReactTooltip>
                              </InputWithIcon>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-sm-12">
                            <button
                              type="submit"
                              className="submitBnt btn btn-primary"
                            >
                              Save
                            </button>
                            <button
                              type="button"
                              className="btn btn-secondary ml-3"
                              onClick={() => history.push("/jobs")}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </section>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
});
export default connect(mapStateToProps, null)(StudentProfileForm);
