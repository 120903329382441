import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import InfiniteScrollSpinner from "../../components/ui/InfiniteScrollSpinner";
import Spinner from "../../components/ui/Spinner";
import AdminJobCard from "../../components/AdminJobCard/AdminJobCard";
import SearchInput from "../../components/ui/SearchInput";
import Hero from "../../components/Hero/Hero";
import Modal from "../../components/ui/Modal";
import { MainError } from "../../globalStyles";
import InfiniteScroll from "react-infinite-scroll-component";
import ConfirmationModal from "../../components/ui/ConfirmationModal";
import { adminPaginatedJobs, deleteJob } from "../../firestoreApi";

function AdminView() {
  const history = useHistory();
  const [jobs, setJobs] = useState([]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [companyName, setCompanyName] = useState("");
  const [confirmJob, setConfirmForJobDelete] = useState(false);
  const [handleNext, setHandleNext] = useState();
  const [pageEnd, setPageEnd] = useState(false);

  const dataperpage = 12;
  useEffect(() => {
    getNextPageHandle(companyName);
  }, [companyName]);

  useEffect(() => {
    if (handleNext) {
      handlePageEnd(true);
    }
  }, [handleNext]);
  const getNextPageHandle = (companyName) => {
    const next = adminPaginatedJobs(dataperpage, companyName);
    setHandleNext(() => next);
  };

  const handlePageEnd = async (restart) => {
    let data = await handleNext();
    if (data.length < dataperpage) {
      setPageEnd(true);
    }
    if (restart) {
      setJobs(data);
    } else {
      setJobs([...jobs, ...data]);
    }
    setLoading(false);
  };

  const handleDelete = async () => {
    setLoading(true);
    try {
      await deleteJob(confirmJob.id);
      setJobs((p) => {
        const newJobs = p.filter((job) => job.id != confirmJob.id);
        return newJobs;
      });
      setLoading(false);
    } catch (e) {
      setError(`Unable to delete try again`);
      setLoading(false);
    }
    setConfirmForJobDelete(false);
  };

  return (
    <>
      <Modal
        show={error || confirmJob}
        onClose={() => {
          setError(false);
          setConfirmForJobDelete(false);
        }}
      >
        {error ? (
          <MainError className="p-3 text-center">{error}</MainError>
        ) : (
          <ConfirmationModal
            title="Alert"
            content="Are you sure you want to delete this job"
            confirmText="delete"
            onConfirm={handleDelete}
            onClose={() => setConfirmForJobDelete(false)}
          />
        )}
      </Modal>
      <div>
        <Hero title="All Jobs" />
        <section className="section">
          <div className="container">
            <div className="row">
              <div className="col-lg-4">
                <div className="card border-0 sidebar sticky-bar rounded shadow bg-light">
                  <div className="card-body">
                    <div className="widget mb-4 pb-2">
                      <div className="col-lg-12 p-0">
                        <SearchInput
                          placeholder="Search Company.."
                          setFilter={(value) => setCompanyName(value)}
                        />
                      </div>
                      <button
                        className="btn btn-primary btn-block"
                        onClick={() => history.push("add_job")}
                      >
                        Add a Job
                      </button>
                      <button
                        className="btn btn-primary btn-block"
                        onClick={() => history.push("add_student")}
                      >
                        Add a Student
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-8 mt-4 mt-lg-0 pt-2 pt-lg-0">
                {loading ? (
                  <div className="text-center my-5" style={{ width: "100%" }}>
                    <Spinner />
                  </div>
                ) : (
                  <div className="table-responsive bg-white shadow rounded w-100">
                    <InfiniteScroll
                      dataLength={jobs.length}
                      next={handlePageEnd}
                      hasMore={!pageEnd}
                      className="w-100"
                      loader={
                        <div className="w-100 text-center py-3">
                          <InfiniteScrollSpinner />
                        </div>
                      }
                      endMessage={
                        <p
                          className="w-100 text-center py-3"
                          style={{ color: "lightgrey", fontSize: "14px" }}
                        >
                          <b>No more jobs to show</b>
                        </p>
                      }
                    >
                      <table className="table mb-0 table-center">
                        <thead className="bg-light">
                          <tr>
                            <th scope="col" style={{ paddingLeft: "3rem" }}>
                              Jobs
                            </th>
                            <th scope="col" className="text-center">
                              Applicants
                            </th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {jobs.map((job, idx) => (
                            <AdminJobCard
                              key={idx}
                              job={job}
                              setLoading={setLoading}
                              setError={setError}
                              setJobs={setJobs}
                              setConfirmForJobDelete={setConfirmForJobDelete}
                            />
                          ))}
                        </tbody>
                      </table>
                    </InfiniteScroll>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default AdminView;
