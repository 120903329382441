import { ref,uploadBytes,getDownloadURL } from 'firebase/storage';
import { v4 as uuidv4 } from 'uuid';
import { emailToKey } from ".";
import { EmailAuthProvider,reauthenticateWithCredential,signInWithEmailAndPassword, updatePassword } from "firebase/auth";
import app, { auth, firebaseDB, storage } from "../firebase";
// const storage = getStorage();
// const storageRef = ref(storage, 'some-child');

// // 'file' comes from the Blob or File API
// uploadBytes(storageRef, file).then((snapshot) => {
//   console.log('Uploaded a blob or file!');
// });
export const handleFileUpload = (
  nodeName,
  file
) => {
  return new Promise(async (resolve,reject) => {
    if (!(file instanceof File || file instanceof Blob)) {
      console.error("Not a file");
      reject("Not a file");
    }
    let uId = uuidv4();
    const storageRef = ref(storage,`${nodeName}/${uId}`);
    try {
      await uploadBytes(storageRef,file)
      let url = await getDownloadURL(storageRef);
      resolve(url);
    } catch(e) {
      console.error(e);
      reject(e);
    }
  })
  
};

export const signIn = (data, setLoading, setError) => {
  signInWithEmailAndPassword(auth,data.email, data.password)
    .then(() => setLoading(false))
    .catch((err) => {
      setLoading(false);
      if (err.code === "auth/wrong-password") {
        setError("Invalid email or password");
      } else {
        setError(err.message);
      }
      setTimeout(() => setError(false), 4000);
    });
};

export const signUp = (data, setLoading, setError) => {
  auth
    .createUserWithEmailAndPassword(data.email, data.password)
    .then((res) => {
      const userDetails = {
        email: res.user.email,
        id: res.user.uid,
      };
      const key = emailToKey(data.email);
      app
        .database()
        .ref(`users/${key}`)
        .remove()
        .then(() => {
          firebaseDB.child("users").child(res.user.uid).set(userDetails);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
          setError("error while registering try again");
          setTimeout(() => setError(false), 4000);
        });
    })
    .catch((err) => {
      setLoading(false);
      setError(err.message);
      setTimeout(() => setError(false), 4000);
    });
};

export const changePassword = (
  currentPassword,
  newPassword
) => {
  return new Promise((resolve,reject) => {
    const user = auth.currentUser;
    const credential = EmailAuthProvider.credential(
      auth.currentUser.email,
      currentPassword
    )
    reauthenticateWithCredential(user,credential).then(() => {
      updatePassword(user, newPassword).then(() => {
        resolve();
      }).catch((error) => {
        if(error.code === "auth/weak-password")
          reject("Password should be at least 6 characters")
        reject("An Error occured try again");
      });
    }).catch((error) => {
      if(error.code === "auth/wrong-password")
        reject('Invalid current password');
      reject('An Error occured try again')
    });
  })
};

export const downloadFile = (url, fileName = "file") => {
  const xhr = new XMLHttpRequest();
  xhr.responseType = "blob";
  xhr.onload = function () {
    const blob = xhr.response;
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
    URL.revokeObjectURL(link.href);
  };
  xhr.open("GET", url);
  xhr.send();
};
