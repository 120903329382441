import React from "react";
import classes from "./styles.module.css";

function index(props) {
  return (
    <div className={classes["lds-ellipsis"]}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
}

export default index;
