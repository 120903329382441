import React from "react";
import { connect } from "react-redux";
import { Switch, Route, Redirect } from "react-router";
import { routes } from "./index";

function RouteComponent({ isAuthenticated, user }) {
  const routerender = [];
  routes.forEach((route, idx) => {
    if (route.admin) {
      if (user && user.admin) {
        routerender.push(
          <Route
            key={idx}
            path={route.path}
            exact={route.exact}
            name={route.name}
            render={(props) => (
              <>
                <route.component {...props} />
              </>
            )}
          />
        );
      }
    } else if (isAuthenticated && route.hideonauth) {
    } else if (isAuthenticated && route.private) {
      routerender.push(
        <Route
          key={idx}
          path={route.path}
          exact={route.exact}
          name={route.name}
          render={(props) => (
            <>
              <route.component {...props} />
            </>
          )}
        />
      );
    } else if (route.component && !route.private) {
      routerender.push(
        <Route
          key={idx}
          path={route.path}
          exact={route.exact}
          name={route.name}
          render={(props) => (
            <>
              <route.component {...props} />
            </>
          )}
        />
      );
    }
  });

  if (isAuthenticated && user.admin) {
    routerender.push(
      <Route
        key="adminredirect"
        path="/"
        exact
        render={(props) => <Redirect to="/admin_view" />}
      />
    );
  }

  if (isAuthenticated && !user.admin) {
    routerender.push(
      <Route
        key="studentredirect"
        path="/"
        exact
        render={(props) => <Redirect to="/jobs" />}
      />
    );
  }
  if (!isAuthenticated) {
    routerender.push(
      <Route
        key="loginredirect"
        path="/"
        exact
        render={(props) => <Redirect to="/login" />}
      />
    );
  }

  routerender.push(<Redirect to="/" key="redirect" />);

  return <Switch>{routerender}</Switch>;
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.isAuthenticated,
  user: state.user,
});
export default connect(mapStateToProps, null)(RouteComponent);
